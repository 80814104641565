import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import useBreakpoint from 'hooks/useBreakpoint';
import {
  formatColorForSwatch,
  selectPopularColors
} from '../utils/colorSelection';

const BREAKPOINT_LIMITS = {
  lg: 7,
  md: 6,
  sm: 5,
  xl: 10
};

const DEFAULT_LIMIT = 14;

/**
 * Validates a color object has the minimum required properties
 * @param {Object} colorWrapper - The color wrapper object
 * @returns {boolean} Whether the color is valid
 */
const isValidColor = (colorWrapper) => {
  const isValid = !!(
    colorWrapper &&
    typeof colorWrapper === 'object' &&
    typeof colorWrapper.id === 'number' &&
    Array.isArray(colorWrapper.simple_names)
  );

  return isValid;
};

/**
 * Hook to manage product color swatches with responsive limits
 * @param {ProductColor[]} productColors - Array of product colors
 * @param {Options} options - Configuration options
 * @returns {{swatchData: Array, remainingCount: number}} Formatted color data and remaining count
 */
export default function useProductColors(productColors = [], options = {}) {
  const breakpoint = useBreakpoint();
  const {limit, useMinQty} = options;
  const maxBreakpointLimit = Math.max(...Object.values(BREAKPOINT_LIMITS));

  // Calculate appropriate limit based on breakpoint
  const breakpointLimit = useMemo(
    () => BREAKPOINT_LIMITS[breakpoint] || DEFAULT_LIMIT,
    [breakpoint]
  );

  // Final limit to use
  const finalLimit = useMemo(
    () => limit || breakpointLimit,
    [limit, breakpointLimit]
  );

  // Memoize the filter functions
  const validColorFilter = useCallback((color) => isValidColor(color), []);
  const minQtyFilter = useCallback((color) => color.min_qty === 1, []);

  // Calculate the full set of colors once
  const {allSwatchData, totalCount} = useMemo(() => {
    if (!Array.isArray(productColors) || !productColors.length) {
      return {
        allSwatchData: [],
        totalCount: 0
      };
    }

    const validColors = productColors.filter(validColorFilter);
    const filteredColors = useMinQty
      ? validColors.filter(minQtyFilter)
      : validColors;

    if (!filteredColors.length) {
      return {
        allSwatchData: [],
        totalCount: 0
      };
    }

    try {
      const {colors, remaining} = selectPopularColors(
        filteredColors,
        maxBreakpointLimit
      );
      return {
        allSwatchData: colors.map(formatColorForSwatch),
        totalCount: colors.length + remaining
      };
    } catch (error) {
      const swatches = validColors
        .slice(0, maxBreakpointLimit)
        .map(formatColorForSwatch);
      return {
        allSwatchData: swatches,
        totalCount: validColors.length
      };
    }
  }, [
    productColors,
    useMinQty,
    validColorFilter,
    minQtyFilter,
    maxBreakpointLimit
  ]);

  // Only slice the results based on finalLimit
  return {
    remainingCount: Math.max(0, totalCount - finalLimit),
    swatchData: allSwatchData.slice(0, finalLimit)
  };
}

useProductColors.propTypes = {
  options: PropTypes.shape({
    limit: PropTypes.number,
    useMinQty: PropTypes.bool
  }),
  productColors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      min_qty: PropTypes.number,
      name: PropTypes.string,
      rbgs: PropTypes.arrayOf(PropTypes.string),
      simple_names: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  )
};
