const titleize = (string) => {
  return string
    .split(' ')
    .map((word) => {
      if (word.trim() === '') return word;
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export {titleize};
