import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import {Box, Button, Typography} from '@customink/pigment-react';
import {CircularProgress} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';

const InfiniteScrollLoading = ({
  buttonTimeout = 5000,
  isError,
  isLoading,
  showMore
}) => {
  const [showShowMoreButton, setShowShowMoreButton] = useState(false);
  const loadingTimer = useRef(null);
  const componentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // Setup intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2 // Trigger when at least 20% of the component is visible
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Handle loading state changes
  useEffect(() => {
    // Clear any existing timer
    if (loadingTimer.current) {
      clearTimeout(loadingTimer.current);
    }

    // Only start timer if component is visible
    if (isVisible && !isLoading && !isError) {
      loadingTimer.current = setTimeout(() => {
        setShowShowMoreButton(true);
      }, buttonTimeout);
    } else {
      setShowShowMoreButton(false);
    }

    return () => {
      if (loadingTimer.current) {
        clearTimeout(loadingTimer.current);
      }
    };
  }, [isLoading, isError, buttonTimeout, isVisible]);

  const handleShowMore = () => {
    setShowShowMoreButton(false);
    showMore();
  };

  return (
    <Box
      ref={componentRef}
      role="status"
      aria-live="polite"
      data-testid="infinite-scroll-loading"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        pb: '30rem',
        px: '2rem'
      }}>
      {isError && (
        <Box sx={{textAlign: 'center'}} data-testid="error-container">
          <Typography color="red" sx={{marginBottom: 1}}>
            Error loading more products
          </Typography>
          <Button
            variant="secondary"
            onClick={handleShowMore}
            data-testid="retry-button"
            aria-label="Retry loading more products">
            Retry
          </Button>
        </Box>
      )}

      {!isError && !showShowMoreButton && (
        <Box
          sx={{
            alignItems: 'center',
            color: designTokens.color.gray.slateDefault.value,
            display: 'flex',
            gap: '0.625rem'
          }}
          data-testid="loading-container">
          <CircularProgress size={25} color="inherit" />
          <Typography fontSize={14}>Loading more products...</Typography>
        </Box>
      )}

      {!isError && showShowMoreButton && (
        <Button
          onClick={handleShowMore}
          data-testid="load-more-button"
          aria-label="Load more products">
          Load More Products
        </Button>
      )}
    </Box>
  );
};

export default InfiniteScrollLoading;
