import React from 'react';
import CurrentFilters from './filters/CurrentFilters';
import CustomClearRefinements from './filters/CustomClearRefinements';
import VirtualMenu from './filters/internal/VirtualMenu';
import VirtualNumericMenu from './filters/internal/VirtualNumericMenu';
import SelectedRefinements from './filters/SelectedRefinements';
import Stats from './filters/Stats';
import VirtualRefinementList from './filters/VirtualRefinementList';
import VirtualPagination from './search/VirtualPagination';
import VirtualSearchBox from './search/VirtualSearchBox';
import VirtualSort from './sort/VirtualSort';
import {INTERNAL_FILTERS} from './utils/algolia_constants';

function SelectedFilters(props) {
  const {
    algoliaIndexPrefix,
    applySearchQuery,
    applyStatData,
    applyUserQuery,
    currentUserInternal,
    defaultQuoteQty,
    filterGroups,
    indexName,
    onRemoveOrderSize,
    pricingAlgorithm,
    quoteQuantityDirty,
    refinementItems,
    searchQuery,
    searchToRefinementListMap,
    setRefinementItems,
    siteWideSearch,
    statData,
    virtualFilterGroupMenus,
    virtualFilterGroupNumericMenus,
    visibleFilters
  } = props;

  const virtualFilterGroups = [
    ...filterGroups,
    'min_qty_colors.simple_names',
    'sort_by',
    ...(currentUserInternal ? INTERNAL_FILTERS : [])
  ];

  const showRemoveFilters = refinementItems.length > 1 || quoteQuantityDirty;

  return (
    <div>
      <VirtualSearchBox />
      <VirtualPagination />
      {virtualFilterGroups?.map((filterGroup) => {
        return (
          <VirtualRefinementList
            attribute={searchToRefinementListMap[filterGroup] || filterGroup}
            key={filterGroup}
            operator={
              filterGroup === 'min_qty_colors.simple_names' ? 'and' : 'or'
            }
          />
        );
      })}
      <VirtualSort
        indexName={indexName}
        defaultQuoteQty={defaultQuoteQty}
        pricingAlgorithm={pricingAlgorithm}
        algoliaIndexPrefix={algoliaIndexPrefix}
      />
      {virtualFilterGroupMenus.map((filterGroupMenu) => {
        return (
          <VirtualMenu attribute={filterGroupMenu} key={filterGroupMenu} />
        );
      })}
      {virtualFilterGroupNumericMenus.map((filterGroupMenu) => {
        return (
          <VirtualNumericMenu
            attribute={filterGroupMenu}
            key={filterGroupMenu}
            items={[{}]}
          />
        );
      })}
      <CurrentFilters setRefinementItems={setRefinementItems} />
      <Stats
        applyUserQuery={applyUserQuery}
        applySearchQuery={applySearchQuery}
        applyStatData={applyStatData}
        siteWideSearch={siteWideSearch}
      />
      {(visibleFilters.length > 0 || searchQuery || quoteQuantityDirty) && (
        <div className="pc-Styles-selectedFilters">
          <div className="pc-selectedFilters">
            <SelectedRefinements
              quoteQuantityDirty={quoteQuantityDirty}
              onRemoveOrderSize={onRemoveOrderSize}
            />
            {showRemoveFilters && (
              <CustomClearRefinements
                onRemoveOrderSize={onRemoveOrderSize}
                quoteQuantityDirty={quoteQuantityDirty}
              />
            )}
          </div>
          <div className="pc-Styles-count">{statData}</div>
        </div>
      )}
    </div>
  );
}

export default SelectedFilters;
