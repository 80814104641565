import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useInfiniteHits, useInstantSearch} from 'react-instantsearch';
import {debounce} from 'throttle-debounce';
import {transformItemsForHits} from './utils/algolia_filters_utils';

export default function useAlgoliaInfiniteScroll({iframer}) {
  const [hasStartedScrolling, setHasStartedScrolling] = useState(false);
  const sentinel = useRef(null);
  const initialRender = useRef(true);
  const {status} = useInstantSearch();

  // Memoize the transform function to prevent re-renders
  const transformItems = useCallback(
    (hits, {results}) => {
      if (!hits) return [];
      return transformItemsForHits(hits, {
        preserveFilters: true,
        results: initialRender.current ? [] : results
      });
    },
    [initialRender]
  );

  const {isLastPage, items, results, showMore} = useInfiniteHits({
    transformItems
  });

  const {error} = useInstantSearch();

  // Memoize hits to prevent unnecessary re-renders
  const hits = useMemo(() => {
    return initialRender.current ? results?.hits : items;
  }, [items, results?.hits, initialRender.current]);

  const isLoading = status === 'loading' || status === 'stalled';
  const isError = Boolean(error);

  // Reset initialRender after first render
  useEffect(() => {
    if (hasStartedScrolling && initialRender.current) {
      initialRender.current = false;
    }
  }, [hasStartedScrolling]);

  // Disable scroll restoration to prevent the page from scrolling to the
  // last position when the user navigates back to the page using the browser's back button as we
  // want to keep the page scrolled to the top until add support for scroll restoration
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto';
      }
    };
  }, []);

  const debouncedShowMore = useMemo(
    () =>
      debounce(300, () => {
        if (!isLoading && !isLastPage) {
          showMore();
        }
      }),
    [showMore, isLastPage, isLoading]
  );

  // Intersection Observer for infinite scroll
  useEffect(() => {
    if (!sentinel.current || isLastPage || !hasStartedScrolling)
      return undefined;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage && !isLoading) {
            debouncedShowMore();
          }
        });
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0
      }
    );

    observer.observe(sentinel.current);
    return () => {
      observer.disconnect();
      debouncedShowMore.cancel();
    };
  }, [isLastPage, hasStartedScrolling, debouncedShowMore, isLoading]);

  // Only enable infinite scroll after user starts scrolling
  useEffect(() => {
    const handleScroll = () => {
      // If the page is iframed, use the body's scrollTop instead of window.scrollY
      // We have to do this because window.scrollY is not available when the iframer is set
      const scrollTop = iframer ? document.body.scrollTop : window.scrollY;

      if (!hasStartedScrolling && scrollTop > 50) {
        setHasStartedScrolling(true);
      }
    };

    const scrollElement = iframer ? document.body : window;
    scrollElement.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, [hasStartedScrolling, iframer]);

  // We need to set hasStartedScrolling to true and initialRender to false
  // because the user has manually clicked the "Load More" button
  const handleShowMore = () => {
    setHasStartedScrolling(true);
    initialRender.current = false;
    showMore();
  };

  return {
    hasStartedScrolling,
    hits,
    isError,
    isLastPage,
    isLoading,
    results,
    sentinel,
    showMore: handleShowMore
  };
}
