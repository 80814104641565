import {useEffect, useState} from 'react';
import DEFAULT_PRICING_ALGORITHM from 'constants/defaultPriceAlgorithm';

function getProductCardPrice(defaultUnitPrices, quantity) {
  const pricingData = defaultUnitPrices?.find(
    (dup) =>
      dup?.algorithm === DEFAULT_PRICING_ALGORITHM &&
      dup?.quantity === quantity &&
      Boolean(dup?.price)
  );

  return pricingData?.price ?? null;
}

export default function useProductCardPrice({
  currency = 'USD',
  defaultUnitPrices,
  locale = 'en-US',
  quantity
}) {
  const [price, setPrice] = useState(() =>
    getProductCardPrice(defaultUnitPrices, quantity)
  );
  const [localizedPrice, setLocalizedPrice] = useState(null);

  useEffect(() => {
    setPrice(getProductCardPrice(defaultUnitPrices, quantity));
  }, [defaultUnitPrices, quantity]);

  useEffect(() => {
    setLocalizedPrice(
      price === null
        ? null
        : new Intl.NumberFormat(locale, {
            currency,
            style: 'currency'
          }).format(price)
    );
  }, [price, locale, currency]);

  return {
    localizedPrice,
    price
  };
}
