import {ColorSwatch} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React, {memo, useCallback} from 'react';

function ProductCardColorSwatchItem({isMobile, onClick, onHover, swatch}) {
  // Memoize the handlers to prevent recreating functions on each render
  const handleClick = useCallback(() => {
    onClick(swatch);
  }, [onClick, swatch]);

  const handleMouseEnter = useCallback(() => {
    onHover(swatch);
  }, [onHover, swatch]);

  return (
    <ColorSwatch
      sx={{
        aspectRatio: 1,
        height: isMobile ? '1.5rem' : '1rem',
        width: isMobile ? '1.5rem' : '1rem'
      }}
      color={swatch.color}
      trimRgb={swatch.trimColor}
      value={swatch.value}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    />
  );
}

ProductCardColorSwatchItem.propTypes = {
  isMobile: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  swatch: PropTypes.shape({
    color: PropTypes.string,
    trimColor: PropTypes.string,
    value: PropTypes.string.isRequired
  }).isRequired
};

// Add a custom equality function for more precise memoization control
const arePropsEqual = (prevProps, nextProps) => {
  // Quick reference check for swatch object
  if (
    prevProps.swatch === nextProps.swatch &&
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.onHover === nextProps.onHover
  ) {
    return true;
  }

  // Deep comparison of swatch properties if needed
  return (
    prevProps.swatch.value === nextProps.swatch.value &&
    prevProps.swatch.color === nextProps.swatch.color &&
    prevProps.swatch.trimColor === nextProps.swatch.trimColor &&
    prevProps.isMobile === nextProps.isMobile
  );
};

export default memo(ProductCardColorSwatchItem, arePropsEqual);
