/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import {ArrowDownIcon, IconButton} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useInstantSearch, useQueryRules} from 'react-instantsearch';
import {debounce} from 'throttle-debounce';
import arraysEqual from '../utils/arraysEqual';
import {trackNoResultsFoundWithColorLimitApplied} from '../utils/metrics/listingsMetrics';
import Breadcrumbs from './Breadcrumbs';
import ColorLimitAlert from './ColorLimitAlert';
import CustomHits from './CustomHits';
import DynamicRefinementsList from './filters/DynamicRefinementsList';
import InfiniteScrollLoading from './InfiniteScrollLoading';
import ComparisonForm from './internal/ComparisonForm';
import QuickView from './internal/QuickView';
import NoHits from './NoHits';
import SearchHeader from './SearchHeader';
import useAlgoliaInfiniteScroll from './useAlgoliaInfiniteScroll';
import {INVISIBLE_FILTERS} from './utils/algolia_constants';
import {filterHits} from './utils/algolia_filters_utils';

function ListingsPage(props) {
  const {
    algoliaClient,
    algoliaIndexName,
    algoliaIndexPrefix,
    applyUserQuery,
    backColor,
    categoryId,
    cid,
    currentUserInternal,
    defaultQuoteQty,
    emptyListings,
    filterGroups,
    firstImage,
    frontColor,
    handleQuantityChange,
    iframer,
    internalCatalog,
    isMobile,
    onRemoveOrderSize,
    pricingAlgorithm,
    quantity,
    quoteQuantityDirty,
    railsEnv,
    searchParams,
    searchToRefinementListMap,
    setEmptyListings,
    siteWideSearch,
    subcategoryNavigation,
    width,
    ydhSettings
  } = props;

  const {hits, isError, isLastPage, isLoading, results, sentinel, showMore} =
    useAlgoliaInfiniteScroll({iframer});
  const facetOrdering = results?.renderingContent?.facetOrdering?.facets?.order; // merch override search filters
  const {items: algoliaRules} = useQueryRules();
  const [refinementItems, setRefinementItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [statData, setStatData] = useState('');
  const [scroll, setScroll] = useState(false);
  const [showStyleId, setShowStyleId] = useState('');
  const [compareStyles, setCompareStyles] = useState([]);
  const [renderSkeletons, setRenderSkeletons] = useState(true);
  const [hasFiredAnalytics, setHasFiredAnalytics] = useState(false);
  const {results: algoliaExplainResults} = useInstantSearch();

  useEffect(() => {
    const algoliaDest =
      algoliaExplainResults?.explain?.redirect?.index[0]?.dest;
    if (algoliaDest && !hasFiredAnalytics) {
      window.dataLayer.push({
        ab_test_group: algoliaDest === algoliaIndexName ? 'control' : 'test',
        ab_test_location: 'plp catalog',
        ab_test_name: `algolia test ${algoliaDest}`,
        event: 'ab_test_group'
      });
      setHasFiredAnalytics(true);
    }
  }, [algoliaExplainResults]);

  const isColorLimitFilterApplied = Boolean(searchParams.get('color_limit'));
  const algoliaIndex = algoliaClient.initIndex(algoliaIndexName);
  const prevHitsRef = useRef();
  /**
   * Filters the provided list of hits based on the refinements and the activeOnly flag.
   *
   * By default, `filteredHits` displays both in-stock and out-of-stock products
   * on the category listings and search results pages. As per merch team
   * requirement, out-of-stock products should be hidden when any filters
   * (e.g., those on the left side of the page) are applied.
   */
  const filteredHits = useMemo(() => {
    if (
      hits &&
      prevHitsRef.current &&
      arraysEqual(
        hits.map((h) => h.objectID),
        prevHitsRef.current.map((h) => h.objectID)
      )
    ) {
      return filterHits(
        prevHitsRef.current,
        refinementItems,
        isColorLimitFilterApplied
      );
    }
    prevHitsRef.current = hits;
    return filterHits(hits, refinementItems, isColorLimitFilterApplied);
  }, [hits, refinementItems, isColorLimitFilterApplied]);

  useEffect(() => {
    if (hits?.length > 0 && !hits[0].skipRendering) {
      setRenderSkeletons(false);
    }
  }, [hits]);

  useEffect(() => {
    const listingsWrapper = document.querySelector('.listings-wrapper');
    const listingsPageElement = document.querySelector(
      '.algolia-listing-pages'
    );
    const loading = document.querySelector('.algolia-loading');

    if (!results.__isArtificial) {
      listingsPageElement.style.height = '100%';
      loading.style.display = 'none';
      listingsWrapper.style.display = 'flex';
    }
    setEmptyListings(!!(results.nbHits === 0 && !results.__isArtificial));
  }, [results?.nbHits, results?.__isArtificial]);

  useEffect(() => {
    const comparedProducts =
      ProductCatalog.LocalStorage.read('product_comparison');
    if (comparedProducts) {
      algoliaIndex
        .getObjects(
          comparedProducts.map((product) => `${product.objectID}-none`)
        )
        .then((res) => {
          const stylesToCompare = res.results?.map((style) => {
            const comparedProduct = comparedProducts.find(
              (product) => product?.product_id === style?.product_id
            );
            const productLink =
              style.target_url ??
              style?.breadcrumbs?.[style.breadcrumbs.length - 1]?.path;
            return {...style, ...comparedProduct, productLink};
          });
          setCompareStyles(stylesToCompare);
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.log('e', e));
    }
  }, []);

  useEffect(() => {
    // Tracking when user was given not found page with color filter applied
    // This is only applicable for the NDX iframer in the lab
    const trackNoResultsFoundWithColorLimitAppliedDebounced = debounce(
      2000,
      () => {
        trackNoResultsFoundWithColorLimitApplied();
      }
    );
    if (
      isColorLimitFilterApplied &&
      iframer === 'ndx' &&
      (emptyListings || filteredHits?.length === 0)
    ) {
      trackNoResultsFoundWithColorLimitAppliedDebounced();
    }
    return () => {
      trackNoResultsFoundWithColorLimitAppliedDebounced.cancel();
    };
  }, [emptyListings, filteredHits, iframer, isColorLimitFilterApplied]);

  const searchBreadcrumbs = [{name: 'All Products', path: '/products'}];
  if (filteredHits && filteredHits.length !== 0)
    searchBreadcrumbs.push({name: 'Search Results', path: ''});

  const filterShownClassName = () => {
    const {action, controller} = props;
    if (controller !== 'categories' || action !== 'custom') {
      return ' is-filterSortShown';
    }
    return '';
  };

  const defaultQuoteClassName = () => {
    if (defaultQuoteQty || siteWideSearch) {
      return 'is-defaultQuoteShown';
    }
    return '';
  };

  const filterColorsClassNames = () => {
    const colorFilters =
      refinementItems.find(
        (refinementItem) => refinementItem.attribute === 'colors.simple_names'
      ) ||
      refinementItems.find(
        (refinementItem) =>
          refinementItem.attribute === 'min_qty_colors.simple_names'
      );
    if (colorFilters) {
      const colorFilterClassName = ['is-filteredColor'];
      colorFilterClassName.push(
        ...(colorFilters.refinements ?? []).map(
          (colorFilter) => `is-filteredColor--${colorFilter.value}`
        )
      );
      return colorFilterClassName.join(' ');
    }
    return '';
  };

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 200);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      // cleanup function to remove the listener
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    ProductCatalog.LocalStorage.write(
      'product_comparison',
      compareStyles.map((style) => {
        return {
          compareImageUrl: style.compareImageUrl,
          name: style.name,
          objectID: style.product_id
        };
      })
    );
    const compareModalPrepend = document.querySelector(
      '.mfp-bg.pc-Modal.pc-ComparisonModal.mfp-ready'
    );
    if (compareStyles.length === 0 && compareModalPrepend) {
      compareModalPrepend.remove();
    }
  }, [compareStyles]);

  const currentColors = () => {
    const colorFilters = refinementItems.find(
      (refinementItem) => refinementItem.attribute === 'colors.simple_names'
    );
    if (colorFilters) {
      const colors = colorFilters.refinements?.map(
        (colorFilter) => colorFilter.value
      );
      return colors || [];
    }
    return [];
  };

  const category = algoliaRules.find((item) =>
    Object.keys(item).includes('category')
  )?.category;
  const badgingRules = algoliaRules.find((item) =>
    Object.keys(item).includes('badges')
  );
  const sizeOrder = algoliaRules.find((item) =>
    Object.keys(item).includes('sizeOrder')
  );
  const filterValuesOrder = algoliaRules.find((item) =>
    Object.keys(item).includes('filterValuesOrder')
  );
  const filterGroupList = algoliaRules.find((item) =>
    Object.keys(item).includes('filterGroups')
  );

  const defaultSearchFilterGroups = algoliaRules.find((item) =>
    Object.keys(item).includes('searchFilters')
  ); // default search filter groups from algolia rules
  const searchViewFilterGroups =
    facetOrdering || defaultSearchFilterGroups?.searchFilters;
  const virtualFilterGroups = [...filterGroups];
  const virtualFilterGroupMenus = ['specialty'];
  const virtualFilterGroupNumericMenus = [
    'min_order_qty',
    'rush_delivery_turntimes'
  ];

  searchViewFilterGroups?.forEach((searchViewFilterGroup) => {
    if (!filterGroups.includes(searchViewFilterGroup)) {
      virtualFilterGroups.push(searchViewFilterGroup);
    }
  });

  const applySearchQuery = (query) => {
    setSearchQuery(query);
  };

  const applyStatData = (resultString) => {
    setStatData(resultString);
  };

  const pcStylesDataAttributes = {};
  pcStylesDataAttributes['data-catid'] =
    categoryId && !searchQuery ? categoryId : '';
  pcStylesDataAttributes['data-name'] =
    categoryId && !searchQuery ? category?.name : '';
  pcStylesDataAttributes['data-filtered'] = refinementItems?.length > 0;
  pcStylesDataAttributes['data-sorted'] =
    !!window.location.search.includes('sort_by=');
  pcStylesDataAttributes['data-search-term'] = searchQuery;
  pcStylesDataAttributes['data-insights-index'] = algoliaIndexName;

  const listingClassAttr = () => {
    let className = 'pc-Styles';
    if (siteWideSearch) {
      className += ' is-searchResults';
    }
    return className;
  };

  const visibleFilters = refinementItems?.filter(
    (refinementItem) => !INVISIBLE_FILTERS.includes(refinementItem.attribute)
  );

  const renderQuickView = (styleId) => {
    const quickViewStyle = filteredHits.filter(
      (hit) => hit.product_id === styleId
    )[0];
    return <QuickView style={quickViewStyle} onClose={setShowStyleId} />;
  };

  const renderComparisonForm = () => {
    return (
      <ComparisonForm
        styles={compareStyles}
        compareHandler={setCompareStyles}
      />
    );
  };

  const fireMetricsEvent = (eventCategory, eventAction, eventLabel) => {
    CustomInk.Metrics.fireEvent(eventCategory, eventAction, eventLabel);
  };

  return (
    <>
      <div className={listingClassAttr()} {...pcStylesDataAttributes}>
        {showStyleId && renderQuickView(showStyleId)}
        {compareStyles.length >= 1 &&
          currentUserInternal &&
          !isMobile &&
          renderComparisonForm()}

        {filteredHits && filteredHits.length > 0 && (
          <div className="pc-Styles-body sb-Wrapper">
            <div
              className={`pc-Styles-products${filterShownClassName()} ${filterColorsClassNames()} ${defaultQuoteClassName()}`}
              data-colors={currentColors()}>
              {(category || siteWideSearch) && (
                <Breadcrumbs
                  categoryName={category && category.name}
                  breadcrumbs={
                    siteWideSearch ? searchBreadcrumbs : category?.breadcrumbs
                  }
                  iframer={iframer}
                  searchParams={searchParams}
                />
              )}
              <SearchHeader
                applySearchQuery={applySearchQuery}
                applyStatData={applyStatData}
                algoliaClient={algoliaClient}
                algoliaIndexPrefix={algoliaIndexPrefix}
                applyUserQuery={applyUserQuery}
                categoryName={category?.name}
                currentUserInternal={currentUserInternal}
                setRefinementItems={setRefinementItems}
                defaultQuoteQty={defaultQuoteQty}
                filterGroups={filterGroups}
                hitsLength={filteredHits?.length}
                indexName={algoliaIndexName}
                isMobile={isMobile}
                onRemoveOrderSize={onRemoveOrderSize}
                pricingAlgorithm={pricingAlgorithm}
                quoteQuantityDirty={quoteQuantityDirty}
                railsEnv={railsEnv}
                refinementItems={refinementItems}
                searchParams={searchParams}
                searchQuery={searchQuery}
                searchToRefinementListMap={searchToRefinementListMap}
                siteWideSearch={siteWideSearch}
                statData={statData}
                visibleFilters={visibleFilters}
                virtualFilterGroupMenus={virtualFilterGroupMenus}
                virtualFilterGroupNumericMenus={virtualFilterGroupNumericMenus}
                width={width}
                hits={filteredHits}
                scroll={scroll}
              />
              {isColorLimitFilterApplied && <ColorLimitAlert />}
              <CustomHits
                algoliaIndex={algoliaIndex}
                currentFilters={refinementItems}
                categoryId={categoryId}
                categoryName={category && category.name}
                defaultQuoteQty={defaultQuoteQty}
                siteWideSearch={siteWideSearch}
                badges={badgingRules?.badges}
                internalCatalog={internalCatalog}
                quickViewHandler={setShowStyleId}
                quickViewStyle={showStyleId}
                quantity={quantity}
                frontColor={frontColor}
                backColor={backColor}
                useDynamicPricing={currentUserInternal}
                iframer={iframer}
                cid={cid}
                ydhSettings={ydhSettings}
                firstImage={firstImage}
                pageNumber={results.page}
                compareHandler={setCompareStyles}
                compareStyles={compareStyles}
                quoteQuantityDirty={quoteQuantityDirty}
                renderSkeletons={renderSkeletons}
                hits={filteredHits}
              />
              <div
                ref={sentinel}
                style={{
                  height: '20px',
                  margin: '20px 0',
                  visibility: 'hidden'
                }}
              />
              {!isLastPage && (
                <InfiniteScrollLoading
                  showMore={showMore}
                  isLoading={isLoading}
                  isError={isError}
                />
              )}
            </div>
            {!emptyListings && filteredHits?.length > 0 && (
              <div className="pc-Styles-filters">
                <DynamicRefinementsList
                  algoliaIndex={algoliaIndex}
                  filterGroups={
                    siteWideSearch ? searchViewFilterGroups : filterGroups
                  }
                  refinementItems={refinementItems}
                  filterValues={
                    siteWideSearch ? {} : algoliaRules?.[0]?.filter_slugs
                  }
                  filterValuesOrder={filterValuesOrder?.filterValuesOrder}
                  sizeOrder={sizeOrder?.sizeOrder}
                  appliedColors={currentColors()}
                  internalCatalog={internalCatalog}
                  currentUserInternal={currentUserInternal}
                  quantity={quantity}
                  quoteQuantityDirty={quoteQuantityDirty}
                  onRemoveOrderSize={onRemoveOrderSize}
                  filterGroupList={filterGroupList?.filterGroups}
                  handleQuantityChange={handleQuantityChange}
                  siteWideSearch={siteWideSearch}
                  subcategoryNavigation={subcategoryNavigation}
                />
              </div>
            )}
          </div>
        )}
        {(emptyListings || filteredHits?.length === 0) && (
          <div>
            {isColorLimitFilterApplied && category && iframer === 'ndx' && (
              <div className="sb-Wrapper">
                <Breadcrumbs
                  categoryName={category.name}
                  breadcrumbs={category.breadcrumbs}
                  iframer={iframer}
                  searchParams={searchParams}
                />
                <ColorLimitAlert />
              </div>
            )}
            <NoHits searchQuery={searchQuery} />
          </div>
        )}
      </div>
      <IconButton
        fill
        onClick={() => {
          window.scrollTo({behavior: 'smooth', top: 0});
          fireMetricsEvent(
            'product listing page',
            'scroll arrow',
            'back to top'
          );
        }}
        title="Back to top"
        sx={{
          bottom: scroll ? '1rem' : '-2.5rem',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.2)',
          height: '2.5rem',
          position: 'fixed',
          right: '1rem',
          transition: 'bottom 300ms',
          width: '2.5rem',
          zIndex: 1000
        }}>
        <ArrowDownIcon
          fontSize="1.5rem"
          style={{
            transform: scroll
              ? 'rotate(180deg)'
              : 'rotate(180deg) scale(2.5, 0.5)',
            transition: 'transform 300ms'
          }}
        />
      </IconButton>
    </>
  );
}

ListingsPage.propTypes = {
  action: PropTypes.string,
  category_id: PropTypes.string,
  controller: PropTypes.string,
  filterGroups: PropTypes.array,
  railsEnv: PropTypes.string,
  searchToRefinementListMap: PropTypes.object
};

export default ListingsPage;
