import {Box} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React from 'react';

export default function MinQty({minQty}) {
  return (
    <Box
      sx={{
        fontSize: '0.8125rem',
        lineHeight: '1.25',
        margin: '-0.1rem 0 -0.15rem'
      }}>
      {minQty ? `Minimum Quantity ${minQty}` : 'No Minimum'}
    </Box>
  );
}

MinQty.propTypes = {
  minQty: PropTypes.number
};
