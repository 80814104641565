import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {useEffect, useMemo, useState} from 'react';
import rollbar from 'src/vendor/rollbar';
import NewProductCard from '../features/products/components/ProductCard/ProductCard';
import newProductCardEnabled from '../utils/newProductCard';
import {ProductCardPlaceholders} from './Placeholders';
import EcoFriendlyBadge from './product_card/EcoFriendlyBadge';
import MadeInUSABadge from './product_card/MadeInUSABadge';
import ProductCard from './product_card/ProductCard';
import {ALWAYS_NEW_BADGE_PRODUCT_IDS} from './utils/algolia_constants';

const badgingNameMap = {
  bestSeller: 'best_seller_badge_ids',
  customerFavorite: 'customer_favorite_badge_ids',
  staffPick: 'staff_pick_badge_ids'
};

const hasNewBadge = ({badges, hit}) => {
  return (
    (badges.new_badge_style_ids?.includes(parseInt(hit.product_id, 10)) &&
      !badges.excluded_new_badge_ids?.includes(parseInt(hit.product_id, 10))) ||
    ALWAYS_NEW_BADGE_PRODUCT_IDS.includes(parseInt(hit.product_id, 10))
  );
};

const hasRecommendationBadge = ({
  activeCategoryId,
  badgeName,
  badges,
  hit = {}
}) => {
  if (badges == null) {
    return false;
  }
  if (
    Object.keys(badges[badgingNameMap[badgeName]]).includes(
      String(hit.product_id)
    )
  ) {
    const hitBadgeArray =
      badges[badgingNameMap[badgeName]][String(hit.product_id)];
    return hitBadgeArray.includes(parseInt(activeCategoryId, 10));
  }
  return false;
};

const lowerProductCardBadge = ({badges, hit}) => {
  // Early exit if badges are null
  if (!badges) return null;

  // Define badge types and associated components
  const badgeChecks = [
    {
      component: <EcoFriendlyBadge productName={hit.name} />,
      ids: badges.eco_style_badge_ids
    },
    {component: <MadeInUSABadge />, ids: badges.usa_style_badge_ids}
  ];

  // Find and return the first applicable badge component
  const applicableBadge = badgeChecks.find((badge) =>
    badge.ids?.includes(parseInt(hit.product_id, 10))
  );
  return applicableBadge ? applicableBadge.component : null;
};

const Badge = ({content, recommendation = false, secondary = false}) => {
  let className = 'pc-ProductCard-promoBadge';
  if (secondary) className += ' pc-ProductCard-promoBadge--secondary';
  if (recommendation) className += ' pc-ProductCard-promoBadge-recommendation';
  return <span className={className}>{content}</span>;
};

const upperProductCardBadge = ({badges, categoryId, hit, iframer}) => {
  if (!badges) return null;

  // Check for sale badge
  if (badges.sale_badge_ids?.includes(parseInt(hit.product_id, 10))) {
    return Badge({content: 'SALE', iframer, secondary: true});
  }

  // Check for recommendation badges only if not a site-wide search
  const badgeTypes = ['bestSeller', 'customerFavorite', 'staffPick'];
  const badgeLabels = {
    bestSeller: 'Best\nSeller',
    customerFavorite: 'Customer\nFave',
    staffPick: 'Staff\nPick'
  };
  const activeCategoryId = categoryId || hit.primary_category_id;
  const foundBadge = badgeTypes.find((badgeType) =>
    hasRecommendationBadge({
      activeCategoryId,
      badgeName: badgeType,
      badges,
      hit
    })
  );
  if (foundBadge) {
    return Badge({
      content: badgeLabels[foundBadge],
      iframer,
      recommendation: true
    });
  }

  // Check for new badge
  if (hasNewBadge({badges, hit})) {
    return Badge({content: 'New!', iframer});
  }

  return null;
};

function CustomHits({
  algoliaIndex,
  backColor,
  badges,
  categoryId,
  categoryName,
  cid,
  compareHandler,
  compareStyles,
  currentFilters,
  defaultQuoteQty,
  firstImage,
  frontColor,
  hits,
  iframer,
  indexName,
  internalCatalog,
  page,
  quantity,
  quickViewHandler,
  quoteQuantityDirty,
  renderSkeletons,
  showStyleId,
  siteWideSearch,
  useDynamicPricing,
  ydhSettings
}) {
  const [defaultQuoteQtyMap, setDefaultQuoteQtyMap] = useState({});

  const isMobile = useMobileBreakpoint();
  const isNewProductCardEnabled = newProductCardEnabled();

  useEffect(() => {
    dataLayer.push({event: 'Hits Viewed'});
  }, [hits]);

  useEffect(() => {
    // This useEffect can be eliminated if hit contains 'primary_category_default_quote_qty'
    if (siteWideSearch && algoliaIndex) {
      let isMounted = true;
      const primaryCategoryDefaultQty = {};

      algoliaIndex
        .browseRules({
          batch: (rules) => {
            rules.forEach((rule) => {
              const result = rule.objectID.toString().match(/^qr_(\d+)$/);
              const catId = result?.[1];
              if (catId && !primaryCategoryDefaultQty[catId]) {
                const defaultQty = parseInt(
                  rule?.consequence?.userData?.defaultQuoteQty,
                  10
                );
                if (defaultQty > 0)
                  primaryCategoryDefaultQty[catId] = defaultQty;
              }
            });
          }
        })
        .then(() => {
          if (isMounted) setDefaultQuoteQtyMap(primaryCategoryDefaultQty);
        })
        .catch((error) => {
          rollbar.error('Error browsing Algolia rules', error);
        });
      return () => {
        isMounted = false;
      };
    }
    return undefined;
  }, [algoliaIndex?.indexName, siteWideSearch]);

  const hasColorRefinements = () => {
    return (
      currentFilters.find(
        (refinementItem) => refinementItem.attribute === 'colors.simple_names'
      ) !== undefined
    );
  };

  const minQtyToggleSet = useMemo(() => {
    return currentFilters.some(
      (currentFilter) =>
        currentFilter.attribute === 'has_singles_enabled_colors'
    );
  }, [currentFilters]);

  return (
    <div className="pc-Products" data-insights-index={indexName}>
      {/* Skeleton */}
      {renderSkeletons && (
        <ProductCardPlaceholders isMobile={isMobile} firstImage={firstImage} />
      )}
      {/* Correct hits from Algolia */}
      {!renderSkeletons &&
        hits?.map((hit, index) => {
          return isNewProductCardEnabled ? (
            <NewProductCard
              key={hit.product_id}
              productColors={hit.colors}
              productId={Number(hit.product_id)}
              name={hit.name}
              categoryImages={hit.category_images}
              ratingCount={Number(hit.rating_count)}
              ratingScore={Number(hit.rating_score)}
              generalComments={hit.general_comments}
              sizes={hit.sizes}
              defaultUnitPrices={hit.default_unit_prices}
              frontColor={frontColor}
              backColor={backColor}
              minQty={hit.minimum_quantity}
              noQtyLimit={Boolean(hit.no_minimum) || minQtyToggleSet}
              quantity={quantity}
              singlesEnabledColors={hit.has_singles_enabled_colors}
              specialty={hit.specialty}
              rushDeliveryTurntimes={hit.rush_delivery_turntimes}
            />
          ) : (
            <ProductCard
              hit={hit}
              key={hit.product_id}
              currentFilters={currentFilters}
              categoryId={categoryId}
              categoryName={categoryName}
              defaultQuoteQty={
                defaultQuoteQty ||
                (siteWideSearch && defaultQuoteQtyMap[hit.primary_category_id])
              }
              siteWideSearch={siteWideSearch}
              upperProductCardBadge={upperProductCardBadge({
                badges,
                categoryId,
                hit,
                iframer,
                siteWideSearch
              })}
              lowerProductCardBadge={lowerProductCardBadge({badges, hit})}
              colorFilterApplied={hasColorRefinements()}
              internalCatalog={internalCatalog}
              quickViewHandler={quickViewHandler}
              showStyleId={showStyleId}
              quantity={quantity}
              frontColor={frontColor}
              backColor={backColor}
              useDynamicPricing={useDynamicPricing}
              iframer={iframer}
              cid={cid}
              ydhSettings={ydhSettings}
              firstImage={firstImage}
              isFirstHit={page === 1 && index === 0}
              compareHandler={compareHandler}
              compareStyles={compareStyles}
              minQtyToggleSet={minQtyToggleSet}
              quoteQuantityDirty={quoteQuantityDirty}
            />
          );
        })}
    </div>
  );
}

export default CustomHits;
