import {MIN_QTY_COLORS_FILTER_NAME} from './algolia_constants';
import arraysEqual from '../../utils/arraysEqual';

const displayMinQtyColorsOnProductCard = (facetFilterString, items) => {
  const facetFilters = JSON.parse(facetFilterString);
  const filterSplit = facetFilters.filter((facet) => !Array.isArray(facet));
  const filterColorNames = filterSplit?.map((cf) => cf.split(':')[1]);
  return items.map((item) => {
    const {colors, ...itemProps} = item;
    const filteredColors = item.min_qty_colors?.filter((hitColor) =>
      filterColorNames.some((filterColor) =>
        hitColor.simple_names.includes(filterColor)
      )
    );
    // min_qty_colors don't contain all data (RGBs, sizes, etc) due to index size limitations. Enrich
    // the min quantity colors using the full color data from the colors array.
    const enrichedFilteredColors = filteredColors.map((minQtyColor) => {
      const color = item.colors.find((c) => minQtyColor.id === c.id);
      return {
        ...minQtyColor,
        ...color
      };
    });
    return {
      ...itemProps,
      colors: enrichedFilteredColors
    };
  });
};

const isMinQtyColorsAvailable = (facetFilterString, results) => {
  const facetFilters = JSON.parse(facetFilterString);
  const facets = results.facets;
  const minQtyFacet = facets.find(
    (facet) => facet?.name === MIN_QTY_COLORS_FILTER_NAME
  );
  const availableMinQtyColors = Object.keys(minQtyFacet?.data || {});
  const colorFacets = facetFilters.find((facetFilter) =>
    facetFilter?.[0].includes('colors.simple_names')
  );
  const selectedColors = colorFacets?.map(
    (colorFacet) => colorFacet.split(':')[1]
  );
  return selectedColors?.every((color) =>
    availableMinQtyColors.includes(color)
  );
};

export const filterHits = (allHits, refinements, activeOnly = false) => {
  const filtersApplied = !refinements.every(
    (item) => item.attribute === 'sort_by'
  );
  const shouldFilterInactiveHits = filtersApplied || activeOnly;

  if (shouldFilterInactiveHits) {
    return allHits.filter((hit) => hit.status !== 'inactive');
  }

  return allHits;
};

export const transformItemsForHits = (
  items,
  {results, preserveFilters = false} = {}
) => {
  if (!results || !items) {
    return (items || []).map((item) => ({
      ...item,
      product_id: (item.product_id || item.objectID)?.toString()
    }));
  }

  const resultParams = new URLSearchParams(results.params || '');
  const facetFilterString = resultParams.get('facetFilters');

  if (preserveFilters && facetFilterString) {
    const transformedItems = items.map((item) => ({
      ...item,
      product_id: (item.product_id || item.objectID).toString()
    }));

    if (facetFilterString?.includes(MIN_QTY_COLORS_FILTER_NAME)) {
      return displayMinQtyColorsOnProductCard(
        facetFilterString,
        transformedItems
      );
    }

    return transformedItems;
  }

  if (facetFilterString?.includes(MIN_QTY_COLORS_FILTER_NAME)) {
    return displayMinQtyColorsOnProductCard(facetFilterString, items);
  }
  return items.map((item) => ({
    ...item,
    product_id: (item.product_id || item.objectID).toString()
  }));
};

export const validMinQtyColorsAvailable = (results) => {
  const resultParams = new URLSearchParams(results.params);
  const facetFilterString = resultParams.get('facetFilters');
  if (facetFilterString?.includes('has_singles_enabled_colors')) {
    return true;
  }
  if (
    facetFilterString?.includes('colors.simple_names') &&
    !facetFilterString?.includes(MIN_QTY_COLORS_FILTER_NAME)
  ) {
    return isMinQtyColorsAvailable(facetFilterString, results);
  }
  return true;
};

export const filterGroupHeader = (attribute, filterGroupList) => {
  const filterGroup = filterGroupList?.find(
    (filterGroup) => filterGroup?.slug === attribute
  );
  if (filterGroup) {
    return filterGroup.name;
  }
  let title = attribute.replace(/^fg\-/gi, '');
  let filterGroupSplit = title.split('-');
  let titleArray = filterGroupSplit?.map(
    (fg) => fg[0].toUpperCase() + fg.slice(1)
  );
  return titleArray?.join(' ');
};

export const sortFilterItems = (items, desiredOrder) => {
  return items.sort(
    (a, b) => desiredOrder.indexOf(a.value) - desiredOrder.indexOf(b.value)
  );
};
