import conciseSize from 'utils/conciseSize';
import MASKS_SIZE_OVERRIDE_STYLES from '../constants/masksSizeOverrideStyles';

export default function sizingSummary(productId, sizes) {
  if (MASKS_SIZE_OVERRIDE_STYLES.includes(parseInt(productId))) {
    return 'Youth & Adult';
  }
  if (!sizes?.length) {
    return '';
  }
  if (sizes.length > 1) {
    return `${conciseSize(sizes[0])}-${conciseSize(sizes[sizes.length - 1])}`;
  }
  return conciseSize(sizes[0]);
}
