export const DELIVERY_DAYS_THRESHOLD = 12;

export const getMinimumDeliveryDays = (deliveryTurnTimes) => {
  if (!Array.isArray(deliveryTurnTimes)) {
    return null;
  }

  if (deliveryTurnTimes.length === 0) {
    return null;
  }

  const minDays = Math.min(...deliveryTurnTimes);
  if (!minDays || minDays > DELIVERY_DAYS_THRESHOLD) {
    return null;
  }

  return minDays;
};
