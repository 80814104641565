import {Box, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import rollbar from 'src/vendor/rollbar';
import useProductColors from '../hooks/useProductColors';
import ProductCardColorSwatchItem from './ProductCardColorSwatchItem';

const RemainingCountText = styled(Typography)({
  color: ' #00000091',
  cursor: 'pointer',
  fontSize: '0.8125rem',
  lineHeight: '1rem',
  marginLeft: '-2px',
  whiteSpace: 'nowrap'
});

const SwatchContainer = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  gap: '0.5rem',
  order: '-1',
  width: '100%'
});

export default function ProductCardColorSwatches({
  onSwatchClick,
  onSwatchHover,
  productColors,
  useMinQty = false
}) {
  const isMobile = useMobileBreakpoint();

  // Memoize the options object to prevent unnecessary recalculations
  const colorOptions = useMemo(
    () => ({
      useMinQty
    }),
    [useMinQty]
  );

  // Use the memoized options object
  const {remainingCount, swatchData} = useProductColors(
    productColors,
    colorOptions
  );

  const handleSwatchClick = useCallback(
    (swatch) => {
      onSwatchClick?.(swatch);
    },
    [onSwatchClick]
  );

  const handleSwatchHover = useCallback(
    (swatch) => {
      onSwatchHover?.(swatch);
    },
    [onSwatchHover]
  );

  // Memoize the swatches array to prevent unnecessary re-rendering of children
  const memoizedSwatches = useMemo(
    () =>
      swatchData.map((swatch) => (
        <ProductCardColorSwatchItem
          key={swatch.value}
          swatch={swatch}
          onClick={handleSwatchClick}
          onHover={handleSwatchHover}
          isMobile={isMobile}
        />
      )),
    [swatchData, handleSwatchClick, handleSwatchHover]
  );

  // Memoize the remaining count element to prevent unnecessary re-renders
  const remainingCountElement = useMemo(
    () =>
      remainingCount > 0 ? (
        <Box
          sx={{
            alignSelf: 'center',
            fontSize: 'inherit',
            gridColumn: 'span 2',
            lineHeight: 1,
            verticalAlign: 'middle'
          }}>
          <RemainingCountText>+{remainingCount}</RemainingCountText>
        </Box>
      ) : null,
    [remainingCount]
  );

  return (
    <ErrorBoundary
      fallback={null}
      onError={(error, errorInfo) => {
        rollbar?.error(error, errorInfo);
      }}>
      <SwatchContainer>
        {memoizedSwatches}
        {remainingCountElement}
      </SwatchContainer>
    </ErrorBoundary>
  );
}

ProductCardColorSwatches.propTypes = {
  onSwatchClick: PropTypes.func,
  onSwatchHover: PropTypes.func,
  productColors: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
      rbgs: PropTypes.arrayOf(PropTypes.string),
      trim_hex: PropTypes.string
    })
  ).isRequired,
  useMinQty: PropTypes.bool
};
