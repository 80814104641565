import React, {useState, useEffect, useRef, useMemo} from 'react';
import {QueryClientProvider} from 'react-query';
import PropTypes from 'prop-types';
import {initializeAlgoliaClient} from '../algolia_listing_pages/utils/algolia_client';
import {InstantSearch, Configure} from 'react-instantsearch';
import {history} from 'instantsearch.js/es/lib/routers';
import ListingsPage from '../algolia_listing_pages/ListingsPage';
import searchParams from 'utils/url';
import {
  getCatalogToAlgoliaSortMap,
  getAlgoliaToCatalogSortMap,
  getCatalogToAlgoliaSortMapSimplified,
  getAlgoliaToCatalogSortMapSimplified
} from '../algolia_listing_pages/sort/sortHelpers';
import {Provider} from '@rollbar/react';
import ErrorBoundary from './ErrorBoundary';
import {PigmentThemeProvider} from '@customink/pigment-react';
import queryClient from 'src/shared/queryClient';
import {refinementListToSearchMap} from '../algolia_listing_pages/utils/refinement_utils';
import {
  getPageViewEvent,
  getOctoIdFromCookie,
  getSessionFromCookie
} from '../algolia_listing_pages/utils/data_utils';
import {
  setQueryStringParams,
  deleteQueryStringParams,
  checkAnyQueryParams
} from '../algolia_listing_pages/utils/route_utils';
import {handleQuickQuoteVisibility} from '../algolia_listing_pages/utils/quick_quote_utils';
import QuickQuote from '../algolia_listing_pages/internal/QuickQuote';
import Pricing from '../src/shared/pricing';
import Placeholders from '../algolia_listing_pages/Placeholders';
import {
  instantSearchOnStateChange,
  instantSearchRouterHistoryUrl,
  mapRouteToState,
  mapStateToRoute
} from '../algolia_listing_pages/utils/instantsearch_utils';
import rollbar from 'src/vendor/rollbar';
import AlgoliaIndexMap from '../algolia_listing_pages/constants/AlgoliaIndexMap.js';
import {catalogMat} from '../utils/constants/signalmanConstants';

const algoliaClient = initializeAlgoliaClient();
const oeUserToken = getOctoIdFromCookie();

export const searchClient = (algoliaClient, categoryId, searchParams) => {
  return {
    ...algoliaClient,
    async search(requests) {
      // Intercept the initial search request to Algolia and alter the returned hits. We can't just override the returned
      // hits to an empty array as that, for some reason, results in Algolia not making subsequent requests. This then
      // results in us showing "No results match your query" page. Instead, we return a single hit with a flag to skip
      // rendering. Super hacky, unfortunately, there isn't any other viable solution to this since Algolia client
      // behaves like a black box and batches requests together so you can't really isolate them.
      if (
        requests.every(
          ({params}) =>
            params.facets.toString === ['*'].toString && !params.facetFilters
        )
      ) {
        let responses = await algoliaClient.search(requests);
        const resultsWithNoHits = responses?.results.map((result) => ({
          ...result,
          ...{
            hits: [
              {...result.hits[0], ...{status: 'active', skipRendering: true}}
            ],
            __isArtificial: true
          }
        }));
        return Promise.resolve({
          results: resultsWithNoHits
        });
      }
      // auto-applies filters for either category page, or search
      let filter =
        requests.every(({params}) => !params.query) && categoryId
          ? `status: active OR status: inactive AND categories.id: ${categoryId}`
          : 'status: active OR status: inactive AND NOT metadata.STYLES_TARGET_CATEGORY_PROMOTION: true';

      const colorLimit = searchParams.get('color_limit');
      if (colorLimit && !isNaN(colorLimit)) {
        filter += ` AND (color_limit >= ${colorLimit} OR color_limit = 0)`;
      }

      requests.map((request) => {
        request.params.filters = filter;
        if (request.params.facetFilters) {
          const isSorting = request.params.facetFilters.find((element) => {
            return (
              element[0].startsWith('sort_by') && element[0] !== 'sort_by:none'
            );
          });
          if (isSorting) {
            request.params.enableReRanking = false;
          }
        }
      });

      let responses = await algoliaClient.search(requests);
      const algoliaResults = responses?.results.map((result) => ({
        ...result,
        ...{
          hits:
            result.hits?.length > 0 ? result.hits : [{objectID: 'no-result'}]
        }
      }));
      return Promise.resolve({
        results: algoliaResults
      });
    }
  };
};

const AlgoliaListingPages = (props) => {
  const {
    rails_env,
    filter_groups,
    controller,
    action,
    default_quote_qty,
    current_user_internal,
    ydh_settings,
    subcategory_navigation
  } = props;

  const currentSearchParams = searchParams();
  const [siteWideSearch, setSiteWideSearch] = useState(false);
  const [userQuery, setUserQuery] = useState(
    currentSearchParams.get('keyword') || ''
  );
  const [quantity, setQuantity] = useState(props.default_quote_qty || '');
  const [frontColor, setFrontColor] = useState(1);
  const [backColor, setBackColor] = useState(0);
  const [quoteQuantityDirty, setQuoteQuantityDirty] = useState(false);
  const [emptyListings, setEmptyListings] = useState(false);
  const [cid, _setCid] = useState(currentSearchParams.get('cid') || '');
  const [digest, _setDigest] = useState(
    currentSearchParams.get('digest') || ''
  );
  const [iframer, _setIframer] = useState(
    currentSearchParams.get('iframer') || ''
  );
  const [multiItem, _setMultiItem] = useState(
    currentSearchParams.get('multi_item') || ''
  );
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 700;
  const algoliaIndexName = AlgoliaIndexMap[rails_env] + '_styles';
  const algoliaIndexPrefix = AlgoliaIndexMap[rails_env];
  const categoryId = props.category_id;
  const pricingAlgorithm = new Pricing().defaultAlgorithm;
  const internalCatalog = catalogMat === 'test' && current_user_internal;
  const userToken = oeUserToken || getSessionFromCookie();

  filter_groups?.map((filterGroup) => {
    if (!Object.values(refinementListToSearchMap).includes(filterGroup)) {
      refinementListToSearchMap[filterGroup] = filterGroup;
    }
  });

  const flipObj = (obj) => {
    let flippedObj = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        flippedObj[element] = key;
      }
    }
    return flippedObj;
  };

  const searchToRefinementListMap = flipObj(refinementListToSearchMap);
  const algoliaToCatalogSort = getAlgoliaToCatalogSortMap(
    algoliaIndexName,
    quantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );
  const catalogToAlgoliaSort = getCatalogToAlgoliaSortMap(
    algoliaIndexName,
    quantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );

  const algoliaToCatalogSortSimplified = getAlgoliaToCatalogSortMapSimplified(
    quantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );
  const catalogToAlgoliaSortSimplified = getCatalogToAlgoliaSortMapSimplified(
    quantity,
    pricingAlgorithm,
    algoliaIndexPrefix
  );

  const routing = {
    router: history({
      createURL({qsModule, routeState, location}) {
        return instantSearchRouterHistoryUrl(routeState, location);
      }
    }),
    stateMapping: {
      stateToRoute(uiState) {
        const stateWithoutPage = {
          [algoliaIndexName]: {
            ...uiState[algoliaIndexName],
            page: undefined
          }
        };

        return mapStateToRoute(
          stateWithoutPage,
          algoliaIndexName,
          algoliaToCatalogSort,
          refinementListToSearchMap,
          algoliaToCatalogSortSimplified
        );
      },
      routeToState(routeState) {
        const {page, ...routeStateWithoutPage} = routeState;

        return mapRouteToState(
          routeStateWithoutPage,
          algoliaIndexName,
          catalogToAlgoliaSort,
          searchToRefinementListMap,
          catalogToAlgoliaSortSimplified
        );
      }
    }
  };

  const applyUserQuery = (query) => {
    setUserQuery(query);
  };

  const validCid = () => {
    return cid.length === 14;
  };

  const multiItemEligible = () => {
    if (iframer !== 'ndx') {
      return false;
    } else if (multiItem !== 'true') {
      return false;
    } else if (!validCid()) {
      return false;
    }
    return true;
  };

  const handleQuantityChange = (qty) => {
    if (qty !== quantity) {
      setQuantity(qty);
      setQuoteQuantityDirty(true);
    }
  };
  const handleFrontColorChange = (colorCount) => {
    if (frontColor !== colorCount) {
      setFrontColor(colorCount);
      if (parseInt(colorCount) === 1) {
        deleteQueryStringParams('quote_fc');
      } else {
        setQuoteQuantityDirty(true);
        setQueryStringParams('quote_fc', colorCount);
      }
    }
    if (
      checkAnyQueryParams('min_order_qty', 'estimated_qty', 'quote_bc') &&
      !checkAnyQueryParams('quote_fc')
    ) {
      setQueryStringParams('quote_fc', colorCount);
    }
  };
  const handleBackColorChange = (colorCount) => {
    if (backColor !== colorCount) {
      setBackColor(colorCount);
      if (parseInt(colorCount) === 0) {
        deleteQueryStringParams('quote_bc');
      } else {
        setQuoteQuantityDirty(true);
        setQueryStringParams('quote_bc', colorCount);
      }
    }
    if (
      checkAnyQueryParams('min_order_qty', 'estimated_qty', 'quote_fc') &&
      !checkAnyQueryParams('quote_bc')
    ) {
      setQueryStringParams('quote_bc', colorCount);
    }
  };
  const onRemoveOrderSize = () => {
    setQuoteQuantityDirty(false);
    setQuantity(default_quote_qty || '');
    setFrontColor('1');
    setBackColor('0');
    deleteQueryStringParams('quote_fc');
    deleteQueryStringParams('quote_bc');
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const onStateChange = ({uiState, setUiState}) => {
    return instantSearchOnStateChange(uiState, setUiState, algoliaIndexName);
  };

  useEffect(() => {
    const categoryInfo = {
      id: categoryId,
      name: props.category_name
    };
    const pageViewTag = getPageViewEvent(categoryInfo, userQuery);
    if (!pageViewTag.endsWith('custom')) {
      CustomInk.Metrics.sessionPageViewTag(pageViewTag);
    }

    if (userQuery || !categoryId) {
      setSiteWideSearch(true);
    } else {
      setSiteWideSearch(false);
    }
  }, [userQuery, categoryId]);

  useEffect(() => {
    window.onload = (e) => {
      const currentParams = searchParams();
      if (current_user_internal) {
        const estimatedQty = currentParams.get('estimated_qty');
        const fc = currentParams.get('quote_fc');
        const bc = currentParams.get('quote_bc');
        if (estimatedQty) {
          setQuantity(parseInt(estimatedQty.replace(':', '')));
          setQuoteQuantityDirty(true);
        }
        if (fc) {
          setFrontColor(fc);
          setQuoteQuantityDirty(true);
        }
        if (bc) {
          setBackColor(bc);
          setQuoteQuantityDirty(true);
        }
      }
      if (
        (currentParams.get('min_qty[]') ||
          currentParams.get('min_qty_colors[]')) &&
        filter_groups?.includes('min_qty')
      ) {
        if (document.querySelector('.pc-FiltersGroup--colors.colors')) {
          document.querySelector(
            '.pc-FiltersGroup--colors.colors'
          ).style.display = 'none';
        }
        if (document.querySelector('.pc-FiltersGroup--colors.min_qty_colors')) {
          document.querySelector(
            '.pc-FiltersGroup--colors.min_qty_colors'
          ).style.display = 'flex';
        }
      }
      handleQuickQuoteVisibility(!emptyListings);
    };
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    handleQuickQuoteVisibility(!emptyListings);
  }, [emptyListings]);

  return (
    <div
      className="listings"
      style={{
        ...{
          height: '100%',
          position: 'relative',
          backgroundColor: 'white'
        }
      }}>
      <Provider config={rollbar}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary rollbar={rollbar}>
            <PigmentThemeProvider>
              <Placeholders
                isMobile={isMobile}
                firstImage={props.first_image}
              />
              <InstantSearch
                searchClient={searchClient(
                  algoliaClient,
                  categoryId,
                  currentSearchParams
                )}
                indexName={algoliaIndexName}
                routing={routing}
                onStateChange={onStateChange}
                future={{preserveSharedStateOnUnmount: true}}
                insights={true}>
                <Configure
                  hitsPerPage={iframer ? 8 : 9}
                  filters={'status: active OR status: inactive'}
                  facets={['*']}
                  userToken={userToken}
                  explain={true}
                />
                <div className="listings-wrapper" style={{display: 'none'}}>
                  <ListingsPage
                    categoryId={categoryId}
                    filterGroups={filter_groups}
                    controller={controller}
                    action={action}
                    searchToRefinementListMap={searchToRefinementListMap}
                    algoliaIndexName={algoliaIndexName}
                    applyUserQuery={applyUserQuery}
                    siteWideSearch={siteWideSearch}
                    defaultQuoteQty={default_quote_qty}
                    internalCatalog={internalCatalog}
                    quantity={quantity}
                    frontColor={frontColor}
                    backColor={backColor}
                    currentUserInternal={current_user_internal}
                    quoteQuantityDirty={quoteQuantityDirty}
                    onRemoveOrderSize={onRemoveOrderSize}
                    emptyListings={emptyListings}
                    setEmptyListings={setEmptyListings}
                    iframer={iframer}
                    multiItemEligible={multiItemEligible()}
                    cid={cid}
                    digest={digest}
                    multiItem={multiItem}
                    ydhSettings={ydh_settings}
                    searchParams={currentSearchParams}
                    railsEnv={rails_env}
                    pricingAlgorithm={pricingAlgorithm}
                    algoliaIndexPrefix={algoliaIndexPrefix}
                    isMobile={isMobile}
                    firstImage={props.first_image}
                    algoliaClient={algoliaClient}
                    handleQuantityChange={handleQuantityChange}
                    subcategoryNavigation={subcategory_navigation}
                  />
                </div>
                {current_user_internal && !emptyListings && (
                  <QuickQuote
                    currentUserInternal={current_user_internal}
                    handleQuantityChange={handleQuantityChange}
                    handleFrontColorChange={handleFrontColorChange}
                    handleBackColorChange={handleBackColorChange}
                    quantity={quantity}
                    frontColor={frontColor}
                    backColor={backColor}
                  />
                )}
              </InstantSearch>
            </PigmentThemeProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </Provider>
    </div>
  );
};

AlgoliaListingPages.propTypes = {
  action: PropTypes.string,
  category_id: PropTypes.string,
  controller: PropTypes.string,
  filter_groups: PropTypes.array,
  rails_env: PropTypes.string
};

export default AlgoliaListingPages;
