import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import {Skeleton, Stack, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useProductCardPriceDetails from '../../hooks/useProductCardPriceDetails';

const StrippedTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: designTokens.color.gray.light.value
  }
});

const StyledTableCell = styled(TableCell)({
  border: 'none',
  fontWeight: 700,
  height: '1rem',
  paddingBottom: 0.5,
  paddingTop: 0.5
});

export default function ProductCardPriceDetailsContent({
  backColor,
  frontColor,
  minQty,
  productId,
  quantity,
  singlesEnabled,
  specialty
}) {
  const {isError, isLoading, priceDetailsData, quotableQuantities} =
    useProductCardPriceDetails(
      productId,
      minQty,
      singlesEnabled,
      specialty,
      frontColor,
      backColor,
      quantity
    );

  // TODO: Validate copy for this error message and format with UX team
  if (isError) {
    return (
      <Typography>
        Error fetching price details, please re-open this dialog.
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      <Stack spacing={1}>
        <Typography>
          For a {Math.max(frontColor, backColor)}-color, {backColor > 0 ? 2 : 1}
          -sided design:
        </Typography>
        <Table>
          <TableHead>
            <TableRow sx={{border: 'none'}}>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Price Per Item</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotableQuantities.map((qty, index) => (
              <StrippedTableRow key={`price-details-${qty}`}>
                <TableCell variant="head" sx={{border: 'none', paddingY: 0.5}}>
                  {qty}
                  {index === 0 && ' (minimum)'}
                </TableCell>
                <TableCell variant="head" sx={{border: 'none', paddingY: 0.5}}>
                  {isLoading && (
                    <Skeleton width="3rem" data-testid="price-skeleton" />
                  )}
                  {!isLoading &&
                    priceDetailsData?.[qty] &&
                    `$${priceDetailsData[qty]}`}
                  {!isLoading && !priceDetailsData?.[qty] && '---'}
                </TableCell>
              </StrippedTableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Stack>
        <Typography>All-inclusive pricing includes</Typography>
        <ul>
          <li>
            <Typography>
              <strong>FREE</strong> delivery
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>FREE</strong> artwork review
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>FREE</strong> artwork setup
            </Typography>
          </li>
        </ul>
      </Stack>
    </Stack>
  );
}

ProductCardPriceDetailsContent.propTypes = {
  backColor: PropTypes.number,
  frontColor: PropTypes.number,
  minQty: PropTypes.number,
  productId: PropTypes.number,
  quantity: PropTypes.number,
  singlesEnabled: PropTypes.bool,
  specialty: PropTypes.bool
};
