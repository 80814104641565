import {Dialog, DialogContent} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React from 'react';

export default function ProductCardPriceDetailDialog({
  children,
  onClose,
  open,
  productId,
  title
}) {
  return (
    <Dialog
      id={`price-details-${productId}`}
      title={title}
      titleSx={{
        fontSize: '1.1875rem',
        fontWeight: 700,
        mt: '-0.625rem'
      }}
      open={open}
      onClose={onClose}
      mobileVariant="bottomSheet"
      disableScrollLock>
      <DialogContent dividers={false}>{children}</DialogContent>
    </Dialog>
  );
}

ProductCardPriceDetailDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};
