import {Box, StarFilledIcon, Typography} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  formatRatingCount,
  formatRatingScore,
  generateStructuredDataRatingData
} from '../utils/rating';

function ProductCardRating({count, generalComments, name, score}) {
  const formattedScore = formatRatingScore(score);
  const formattedCount = formatRatingCount(count);
  const structuredData = generateStructuredDataRatingData(
    name,
    count,
    score,
    generalComments
  );

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '0.125rem',
        margin: '-0.125rem 0'
      }}>
      <script type="application/ld+json">{structuredData}</script>

      <StarFilledIcon
        sx={{
          color: '#FFDC1E',
          height: '1.25rem',
          marginLeft: '-0.1875rem',
          transform: 'translateY(-0.0625rem)',
          width: '1.25rem'
        }}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '0.1875rem'
        }}>
        <Typography fontSize="0.875rem" lineHeight="1.25">
          {formattedScore}
        </Typography>
        <span
          data-testid="rating-count"
          style={{
            color: '#00000091',
            fontSize: '0.75rem'
          }}>
          {formattedCount}
        </span>
      </Box>
    </Box>
  );
}

ProductCardRating.propTypes = {
  count: PropTypes.number,
  generalComments: PropTypes.string,
  name: PropTypes.string,
  score: PropTypes.number
};

export default ProductCardRating;
