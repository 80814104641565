import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useRefinementList, useInstantSearch} from 'react-instantsearch';
import {sortFilterItems} from '../utils/algolia_filters_utils';
import {titleize} from "src/utils/string";

const ColorFilterGroup = ({
  sortBy,
  attribute,
  ...remainingProps
}) => {
  const {items, refine} = useRefinementList({attribute, ...remainingProps});
  const {indexUiState, setIndexUiState, results} = useInstantSearch();
  const autoFacets = results.explain?.params?.rules.facetFilters
  const hasSetAutoFacet = useRef(false);
  const labelForAttribute = `fs-colors-`;

  const applyAutoFacets = useCallback((colorAutoFacets) => {
    const existingColorFilters = indexUiState.refinementList['colors.simple_names'] || [];
    // colorAutoFacets are ['colors.simple_names:red', 'colors.simple_names:gray']
    const colorFacetValues = colorAutoFacets.map(facet => facet.split(':')[1]);
    const newColorFilters = [...new Set([...existingColorFilters, ...colorFacetValues])];

    if (JSON.stringify(existingColorFilters) !== JSON.stringify(newColorFilters)) {
      setIndexUiState((prevIndexUiState) => ({
        ...prevIndexUiState,
        refinementList: {
          ...prevIndexUiState.refinementList,
          'colors.simple_names': newColorFilters
        }
      }));
    }
  }, [indexUiState, setIndexUiState]);

  useEffect(() => {
    const colorAutoFacets = autoFacets?.filter(facet => facet.startsWith('colors.simple_names')); // only apply auto facets for color simple names
    if(!hasSetAutoFacet.current && colorAutoFacets) {
      applyAutoFacets(colorAutoFacets);
      hasSetAutoFacet.current = true;
    };
  }, [autoFacets])

  const colorFacetAttributeName = attribute.split('.', 1)[0];
  const upperCamelCase = (color) => titleize(color.replace('-', ' ')).replace(/ /g, '');
  const colorSelected = (item) => (item.isRefined ? 'is-selected' : '');

  const colorSwatchClicked = (event, item) => {
    event.preventDefault();
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'fs-colors',
      filter_value: item.value,
      filter_action: item.isRefined ? 'remove' : 'add'
    });
    refine(item.value);
  };

  return (
    <nav
      className={`pc-FiltersGroup pc-FiltersGroup--colors ${colorFacetAttributeName}`}>
      <header className="pc-FiltersGroup-header expanded">Color Family</header>
      <div className="pc-FiltersGroup-values">
        {sortFilterItems(items, sortBy)?.map((item) => {
          return (
            <label
              htmlFor={labelForAttribute + item.highlighted}
              key={item.highlighted}
              className="sb-Form-checkbox pc-FiltersGroup-value"
              data-insights-filter={`fs-colors:${item.highlighted}`}>
              <span className="sr-only">{titleize(item.value.replace('-', ' '))}</span>
              <input
                type="checkbox"
                id={labelForAttribute + item.highlighted}
                value={item.highlighted}
                title={titleize(item.value.replace('-', ' '))}
              />
              <a
                className="pc-FiltersGroup-link"
                role="link"
                tabIndex="0"
                onClick={(event) => colorSwatchClicked(event, item)}>
                <span className="sb-Form-indicator"></span>
                <div
                  title={titleize(item.value.replace('-', ' '))}
                  className={`pc-Swatch is-${upperCamelCase(item.value)} ${colorSelected(item)}`}></div>
              </a>
            </label>
          );
        })}
      </div>
    </nav>
  );
};

ColorFilterGroup.propTypes = {
  attribute: PropTypes.string,
  limit: PropTypes.number,
  sortBy: PropTypes.array
};

export default ColorFilterGroup;
