import {useClearRefinements} from 'react-instantsearch';

export const useRemoveFilters = () => {
  const {refine} = useClearRefinements({
    excludedAttributes: ['compatible_type', 'query', 'sort_by']
  });

  const fireTags = () => {
    ProductCatalog.GtmListing.fireImpression();
    window.dataLayer.push({
      event: 'catalog_filter_remove',
      filter_remove_type: 'all',
      filter_remove_value: 'Remove Filters'
    });
  };

  const removeFilters = () => {
    fireTags();
    return refine();
  };

  return removeFilters;
};
