import {Box, StarFilledIcon} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React from 'react';

// Product Card optimization A/B test simplification
function SimplifiedRating({
  structuredData,
  styleRatingCount,
  styleRatingScoreData
}) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '2px',
        margin: '-2px 0'
      }}>
      <script type="application/ld+json">{structuredData}</script>
      <StarFilledIcon
        sx={{
          color: '#FFDC1E',
          height: '20px',
          marginLeft: '-3px',
          transform: 'translateY(-1px)',
          width: '20px'
        }}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '3px'
        }}>
        {`${styleRatingScoreData} `}
        <span
          style={{
            color: '#00000091',
            fontSize: '12px'
          }}>{`${styleRatingCount}`}</span>
      </Box>
    </Box>
  );
}

function Rating(props) {
  const {generalComments, name, ratingCount, ratingScore} = props;

  const styleRatingScoreData = () => {
    let score = Number(ratingScore).toFixed(1);
    if (score.endsWith('0')) {
      score = parseInt(score).toString();
    }
    return score;
  };

  const styleRatingCount = () => {
    let capped = false;
    let count = ratingCount;
    const capLimit = 10000;
    if (count > capLimit) {
      count = capLimit;
      capped = true;
    }
    let delimitedRatingCount = count.toLocaleString();
    if (capped) {
      delimitedRatingCount += '+';
    }
    return `(${delimitedRatingCount})`;
  };

  const structuredData = () => {
    const data = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: '5',
        ratingCount: `${ratingCount}`,
        ratingValue: ratingScore
      },
      description: generalComments,
      name
    };

    return JSON.stringify(data);
  };

  return (
    <SimplifiedRating
      structuredData={structuredData()}
      styleRatingCount={styleRatingCount()}
      styleRatingScoreData={styleRatingScoreData()}
    />
  );
}

Rating.propTypes = {
  generalComments: PropTypes.string,
  name: PropTypes.string,
  ratingCount: PropTypes.number,
  ratingScore: PropTypes.string
};

export default Rating;
