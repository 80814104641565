import {styled} from '@customink/pigment-react/lib/themeCustomink';
import {Box, Popper} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

const StyledPopper = styled(Popper)({
  '& .arrow': {
    '&:before': {
      borderStyle: 'solid',
      content: '""',
      display: 'block',
      height: 0,
      width: 0
    },
    position: 'absolute'
  },
  '&:before': {
    background: 'transparent',
    content: '""',
    height: '0.5rem',
    left: 0,
    position: 'absolute',
    right: 0,
    top: '-0.5rem'
  },
  '&[data-popper-placement*="bottom"] .arrow': {
    '&:before': {
      borderColor: 'transparent transparent white transparent',
      borderWidth: '0 0.5rem 0.5rem 0.5rem'
    },
    marginTop: '-0.5rem',
    top: 0
  },
  '&[data-popper-placement*="top"] .arrow': {
    '&:before': {
      borderColor: 'white transparent transparent transparent',
      borderWidth: '0.5rem 0.5rem 0 0.5rem'
    },
    bottom: 0,
    marginBottom: '-0.5rem'
  },
  backgroundColor: 'white',
  borderRadius: '0.5rem',
  boxShadow: '0 0 1rem rgba(0, 0, 0, 0.1)',
  padding: '1rem',
  zIndex: 10
});

export default function ProductCardPricePopover({
  anchorEl,
  children,
  onMouseEnter,
  onMouseLeave,
  open,
  productId
}) {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <StyledPopper
      id={`price-details-${productId}`}
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      modifiers={[
        {
          enabled: true,
          name: 'arrow',
          options: {
            element: arrowRef
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Box className="arrow" ref={setArrowRef} />
      {children}
    </StyledPopper>
  );
}

ProductCardPricePopover.propTypes = {
  anchorEl: PropTypes.object,
  children: PropTypes.node.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired
};
