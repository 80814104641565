import React from 'react';
import { useNumericMenu, useRefinementList, useCurrentRefinements } from 'react-instantsearch';
import DeliveryRushChip from '../../components/DeliveryRushChip';

const RushDeliveryTurntimesFilterGroup = (props) => {

  const { items: nonNumericItems } = useRefinementList(props)
  const idealItems = [
    { label: '3 days', end: 3 },
    { label: '1 week', end: 7 },
    { label: '10 days', end: 10 },
    { label: '12 days', end: 12 }
  ]

  const minRushDelivery = Math.min(...nonNumericItems.map(nonNumericItem => parseInt(nonNumericItem.value, 10)));
  const actualItems = idealItems.filter(item => item.end >= minRushDelivery);


  const {items, refine} = useNumericMenu({
    attribute: 'rush_delivery_turntimes',
    items: actualItems?.length > 0 ? actualItems : idealItems
  })

  const { items: clearItems, refine: unrefine } = useCurrentRefinements({
    includedAttributes: ['rush_delivery_turntimes']
  });

  const fireAnalytics = (item) => {
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'fs-Rush Delivery Available',
      filter_value: item.label,
      filter_action: item.isRefined ? 'remove' : 'add'
    })
  }

  return (
    <nav className="pc-FiltersGroup">
      <header className={'pc-FiltersGroup-header expanded'}>Rush Delivery Available</header>
      <div className='pc-FiltersGroup-values'>
        {
          items.map((item) => {
            const isSuperRush = item.label === '3 days';
            return (
              <label
                htmlFor={`rush-delivery-turntimes-${item.label}`}
                key={`rush-delivery-turntimes-key-${item.label}`}
                className='sb-Form-checkbox pc-FiltersGroup-value'
              >
                <input
                  type="checkbox"
                  id={`rush-delivery-turntimes-${item.label}`}
                  value={item.label}
                  checked={item.isRefined}
                  onChange={() => {}}
                />
                <a className='pc-FiltersGroup-link'
                  role="checkbox"
                  tabIndex="0"
                  onClick={() => {
                    fireAnalytics(item);
                    if(item.isRefined && clearItems[0]?.refinements?.length === 1) {
                      unrefine(clearItems[0]?.refinements[0])
                    } else {
                      refine(item.value);
                    }
                  }}
                >
                  <span className="sb-Form-indicator"></span>
                  <div style={{display: 'flex', gap: '0.5rem',alignItems: 'center'}}>
                    {`${item.label}`}
                    <DeliveryRushChip 
                      label={isSuperRush ? "Super Rush" : "Rush"} 
                      superRush={isSuperRush}
                    />
                  </div>
                </a>
              </label>
            )
          })
        }
      </div>
    </nav>
  )
}

export default RushDeliveryTurntimesFilterGroup
