import {Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import PropTypes from 'prop-types';
import React from 'react';
import sizingSummary from '../utils/sizingSummary';

const SizesInfoText = styled(Typography)({
  fontSize: '0.8125rem',
  fontWeight: 'normal',
  lineHeight: '1.2',
  textAlign: 'left'
});

export default function ProductCardSizesInfo({productId, sizes}) {
  return <SizesInfoText>{sizingSummary(productId, sizes)}</SizesInfoText>;
}

ProductCardSizesInfo.propTypes = {
  productId: PropTypes.number.isRequired,
  sizes: PropTypes.array.isRequired
};
