export function formatRatingScore(score) {
  const formattedScore = Number(score).toFixed(1);
  return formattedScore.endsWith('0')
    ? parseInt(formattedScore).toString()
    : formattedScore;
}

export function formatRatingCount(count) {
  if (count === undefined || Number.isNaN(count)) {
    return null;
  }

  const capLimit = 10000;
  const isCapped = count > capLimit;
  const cappedCount = isCapped ? capLimit : count;
  let formattedCount = cappedCount.toLocaleString();

  if (isCapped) {
    formattedCount += '+';
  }

  return `(${formattedCount})`;
}

export function generateStructuredDataRatingData(
  name,
  ratingCount,
  ratingScore,
  generalComments
) {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: '5',
      ratingCount: `${ratingCount}`,
      ratingValue: ratingScore
    },
    description: generalComments,
    name
  };

  return JSON.stringify(data);
}
