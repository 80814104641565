import {useEffect, useState} from 'react';
import useBreakpoint from './useBreakpoint';

const parseRGB = (rgb) => {
  const clr = {b: 0, g: 0, r: 0};
  const parts = rgb.replace('rgb(', '').replace(')', '').split(',');
  clr.r = parseInt(parts[0]);
  clr.g = parseInt(parts[1]);
  clr.b = parseInt(parts[2]);
  return clr;
};

const breakpointData = {
  lg: {limit: 7},
  md: {limit: 6},
  sm: {limit: 5},
  xl: {limit: 10}
};

const usePopularSwatches = (colors, useMinQty) => {
  const [popColors, setPopColors] = useState([]);
  const [remainingColorsCount, setRemainingColorsCount] = useState(0);
  const breakpoint = useBreakpoint();

  const limitBasedOnBreakpoint = () => breakpointData[breakpoint]?.limit || 14;

  const getNextColor = (filteredColors, avoidThese) => {
    return filteredColors.find((color) => !avoidThese.includes(color)) || null;
  };

  const findBest = (filteredColors, simpleNames, rgbKey, mathSequence) => {
    let best = null;
    let secondBest = null;
    filteredColors.forEach((color) => {
      if (simpleNames.includes(color.simple_names[0])) {
        if (!best) best = color;
        else {
          const colorRgbs = color.rgbValues;
          const currentRgbs = best.rgbValues;
          if (
            colorRgbs[mathSequence[0]] -
              colorRgbs[mathSequence[1]] -
              colorRgbs[mathSequence[2]] >
            0
          ) {
            if (colorRgbs[rgbKey] > currentRgbs[rgbKey]) {
              secondBest = best;
              best = color;
            }
          }
        }
      }
    });
    return {best, secondBest};
  };

  const findBestCombination = (
    filteredColors,
    simpleNames,
    rgbKeys,
    threshold
  ) => {
    let best = null;
    let secondBest = null;
    filteredColors.forEach((color) => {
      if (simpleNames.includes(color.simple_names[0])) {
        if (!best) best = color;
        else {
          const colorRgbs = color.rgbValues;
          const currentRgbs = best.rgbValues;
          if (colorRgbs[rgbKeys[1]] + colorRgbs[rgbKeys[2]] > threshold) {
            if (colorRgbs[rgbKeys[0]] < currentRgbs[rgbKeys[0]]) {
              secondBest = best;
              best = color;
            }
          }
        }
      }
    });
    return {best, secondBest};
  };

  const pickOfSimple = (filteredColors, simpleName) => {
    let best = null;
    let secondBest = null;
    filteredColors.forEach((color) => {
      if (color.simple_names[0] === simpleName) {
        if (best) secondBest = best;
        best = color;
      }
    });
    return {best, secondBest};
  };

  const findBestWhite = (filteredColors) => {
    let best = null;
    let secondBest = null;
    filteredColors.forEach((color) => {
      if (color.simple_names[0] === 'white') {
        if (!best) best = color;
        else {
          const colorRgbs = color.rgbValues;
          const currentRgbs = best.rgbValues;
          if (
            colorRgbs.r + colorRgbs.g + colorRgbs.b >
            currentRgbs.r + currentRgbs.g + currentRgbs.b
          ) {
            secondBest = best;
            best = color;
          }
        }
      }
    });
    return {best, secondBest};
  };

  const findBestColors = (filteredColors) => {
    let limit = limitBasedOnBreakpoint();
    if (filteredColors.length - limit < 2) limit += 1;

    filteredColors.forEach((color) => {
      color.rgbValues = parseRGB(color.rbgs[0]);
    });

    let pops = [];
    const reds = findBest(filteredColors, ['red'], 'r', ['r', 'g', 'b']);
    const greens = findBest(filteredColors, ['green'], 'g', ['g', 'r', 'b']);
    const blues = findBest(filteredColors, ['blue'], 'b', ['b', 'r', 'g']);
    const yellows = findBestCombination(
      filteredColors,
      ['yellow', 'orange'],
      ['b', 'r', 'g'],
      300
    );
    const purples = findBestCombination(
      filteredColors,
      ['purple'],
      ['g', 'r', 'b'],
      150
    );
    const whites = findBestWhite(filteredColors);
    const browns = pickOfSimple(filteredColors, 'brown');
    const heathers = pickOfSimple(filteredColors, 'heathered');

    pops.push(
      blues.best,
      heathers.best,
      reds.best,
      whites.best,
      greens.best,
      purples.best,
      yellows.best,
      browns.best,
      blues.secondBest,
      heathers.secondBest,
      reds.secondBest,
      whites.secondBest,
      greens.secondBest,
      purples.secondBest,
      yellows.secondBest,
      browns.secondBest
    );

    pops = pops.filter(Boolean);

    for (let x = pops.length; x < limit; x += 1) {
      const nextColor = getNextColor(filteredColors, pops);
      if (nextColor) pops.push(nextColor);
    }

    if (pops.length > limit) pops = pops.slice(0, limit);

    setRemainingColorsCount(filteredColors.length - pops.length);
    return pops;
  };

  useEffect(() => {
    let filteredColors = colors || [];
    if (useMinQty) {
      filteredColors = filteredColors.filter((c) => c.min_qty === 1);
    }
    setPopColors(findBestColors(filteredColors));
  }, [colors, useMinQty]);

  return {popColors, remainingColorsCount};
};

export default usePopularSwatches;
