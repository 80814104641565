import {Box, Link, Typography} from '@customink/pigment-react';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import useProductCardPrice from '../../hooks/useProductCardPrice';
import ProductCardPriceDetailDialog from './ProductCardPriceDetailDialog';
import ProductCardPriceDetailsContent from './ProductCardPriceDetailsContent';
import ProductCardPricePopover from './ProductCardPricePopover';

const DEFAULT_QUOTE_QTY = 25;

export default function ProductCardPriceDetails({
  backColor,
  defaultUnitPrices,
  frontColor,
  isMobile,
  minQty,
  name,
  productId,
  quantity,
  singlesEnabled,
  specialty
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorRef = useRef(null);
  const closeTimeoutRef = useRef(null);
  const openTimeoutRef = useRef(null);
  const quoteQty = quantity || DEFAULT_QUOTE_QTY;
  const {localizedPrice} = useProductCardPrice({
    defaultUnitPrices,
    quantity: quoteQty
  });

  // Clean up timeouts on unmount
  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) clearTimeout(closeTimeoutRef.current);
      if (openTimeoutRef.current) clearTimeout(openTimeoutRef.current);
    };
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
      if (openTimeoutRef.current) {
        clearTimeout(openTimeoutRef.current);
      }
      openTimeoutRef.current = setTimeout(() => {
        setIsPopoverOpen(true);
      }, 100);
    }
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) {
      if (openTimeoutRef.current) {
        clearTimeout(openTimeoutRef.current);
      }
      closeTimeoutRef.current = setTimeout(() => {
        setIsPopoverOpen(false);
      }, 100);
    }
  }, [isMobile]);

  const handleClick = (event) => {
    event.preventDefault();
    if (isMobile) {
      setIsModalOpen(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent={isMobile ? 'center' : 'flex-start'}
      flexWrap="wrap"
      gap="0.125rem 0.5rem">
      <Typography fontSize="0.8125rem" lineHeight={1.25}>
        <Box component="span" fontWeight={700}>
          {localizedPrice}
        </Box>
        /ea for {quoteQty} items
      </Typography>
      <Link
        sx={{
          fontSize: '0.75rem',
          fontWeight: '500'
        }}
        ref={anchorRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}>
        Pricing Details
      </Link>
      <Box>
        {isMobile && (
          <ProductCardPriceDetailDialog
            title={name}
            productId={productId}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}>
            <ProductCardPriceDetailsContent
              backColor={backColor}
              frontColor={frontColor}
              minQty={minQty}
              productId={productId}
              quantity={quantity}
              singlesEnabled={singlesEnabled}
              specialty={specialty}
            />
          </ProductCardPriceDetailDialog>
        )}
        {!isMobile && (
          <ProductCardPricePopover
            productId={productId}
            anchorEl={anchorRef.current}
            open={isPopoverOpen}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <ProductCardPriceDetailsContent
              backColor={backColor}
              frontColor={frontColor}
              minQty={minQty}
              productId={productId}
              quantity={quantity}
              singlesEnabled={singlesEnabled}
              specialty={specialty}
            />
          </ProductCardPricePopover>
        )}
      </Box>
    </Box>
  );
}

ProductCardPriceDetails.propTypes = {
  backColor: PropTypes.number.isRequired,
  defaultUnitPrices: PropTypes.arrayOf(PropTypes.object).isRequired,
  frontColor: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  minQty: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  singlesEnabled: PropTypes.bool.isRequired,
  specialty: PropTypes.bool.isRequired
};
