import tinycolor from 'tinycolor2';

/**
 * Parses an RGB color string into an object with r, g, b values
 * @param {string} rgb - RGB color string in format 'rgb(r,g,b)'
 * @returns {{r: number, g: number, b: number}} Object containing RGB values
 */
const parseRGB = (rgb) => {
  try {
    if (!rgb || typeof rgb !== 'string') {
      return {b: 0, g: 0, r: 0};
    }
    const parts = rgb.replace('rgb(', '').replace(')', '').split(',');
    if (parts.length !== 3) {
      return {b: 0, g: 0, r: 0};
    }
    return {
      b: parseInt(parts[2]) || 0,
      g: parseInt(parts[1]) || 0,
      r: parseInt(parts[0]) || 0
    };
  } catch (error) {
    return {b: 0, g: 0, r: 0};
  }
};

/**
 * Finds the best matching colors from a list based on simple name and RGB values
 * @param {Array} colors - Array of color objects with rgbValues and simple_names
 * @param {Array<string>} simpleNames - Array of simple color names to match
 * @param {string} rgbKey - The RGB key to compare ('r', 'g', or 'b')
 * @param {Array<string>} mathSequence - Order of RGB keys for mathematical comparison
 * @returns {Array} Array containing best and second best matching colors
 */
const findBestColors = (colors, simpleNames, rgbKey, mathSequence) => {
  const picks = {
    best: null,
    secondBest: null
  };

  colors.forEach((color) => {
    const simpleName = color.simple_names[0];
    if (simpleNames.includes(simpleName)) {
      if (!picks.best) {
        picks.best = color;
      } else {
        const colorRgbs = color.rgbValues;
        const currentRgbs = picks.best.rgbValues;
        if (
          colorRgbs[mathSequence[0]] -
            colorRgbs[mathSequence[1]] -
            colorRgbs[mathSequence[2]] >
          0
        ) {
          if (colorRgbs[rgbKey] > currentRgbs[rgbKey]) {
            picks.secondBest = picks.best;
            picks.best = color;
          }
        }
      }
    }
  });
  return [picks.best, picks.secondBest].filter(Boolean);
};

/**
 * Finds the best color combination based on RGB thresholds
 * @param {Array} colors - Array of color objects with rgbValues and simple_names
 * @param {Array<string>} simpleNames - Array of simple color names to match
 * @param {Array<string>} rgbKeys - Array of RGB keys for comparison
 * @param {number} threshold - Threshold value for RGB combination
 * @returns {Array} Array containing best and second best matching colors
 */
const findBestColorCombinations = (colors, simpleNames, rgbKeys, threshold) => {
  const picks = {
    best: null,
    secondBest: null
  };

  colors.forEach((color) => {
    const simpleName = color.simple_names[0];
    if (simpleNames.includes(simpleName)) {
      if (!picks.best) {
        picks.best = color;
      } else {
        const colorRgbs = color.rgbValues;
        const currentRgbs = picks.best.rgbValues;
        if (colorRgbs[rgbKeys[1]] + colorRgbs[rgbKeys[2]] > threshold) {
          if (colorRgbs[rgbKeys[0]] < currentRgbs[rgbKeys[0]]) {
            picks.secondBest = picks.best;
            picks.best = color;
          }
        }
      }
    }
  });
  return [picks.best, picks.secondBest].filter(Boolean);
};

/**
 * Picks colors matching a specific simple name
 * @param {Array} colors - Array of color objects with simple_names
 * @param {string} simpleName - Simple name to match
 * @returns {Array} Array containing best and second best matching colors
 */
const findColorsBySimpleName = (colors, simpleName) => {
  const picks = {
    best: null,
    secondBest: null
  };
  colors.forEach((color) => {
    if (color.simple_names[0] === simpleName) {
      if (picks.best) picks.secondBest = picks.best;
      picks.best = color;
    }
  });
  return [picks.best, picks.secondBest].filter(Boolean);
};

/**
 * Finds the best white colors based on RGB values
 * @param {Array} colors - Array of color objects with rgbValues and simple_names
 * @returns {Array} Array containing best and second best white colors
 */
const findBestWhiteColors = (colors) => {
  const picks = {
    best: null,
    secondBest: null
  };
  colors.forEach((color) => {
    const simpleName = color.simple_names[0];
    if (simpleName === 'white') {
      if (!picks.best) {
        picks.best = color;
      } else {
        const colorRgbs = color.rgbValues;
        const currentRgbs = picks.best.rgbValues;
        if (
          colorRgbs.r + colorRgbs.g + colorRgbs.b >
          currentRgbs.r + currentRgbs.g + currentRgbs.b
        ) {
          picks.secondBest = picks.best;
          picks.best = color;
        }
      }
    }
  });
  return [picks.best, picks.secondBest].filter(Boolean);
};

/**
 * Cache for memoized color formatting results
 * This reduces redundant color conversions for the same colors
 * Using a limited size LRU-like cache to prevent memory leaks
 */
const MAX_CACHE_SIZE = 200;
const colorFormatCache = {
  // Internal cache storage
  cache: new Map(),
  clear() {
    this.cache.clear();
    this.keys = [];
  },

  get(key) {
    if (this.cache.has(key)) {
      // Move the key to the end of the array (most recently used)
      this.keys = this.keys.filter((k) => k !== key);
      this.keys.push(key);

      return this.cache.get(key);
    }
    return undefined;
  },

  has(key) {
    return this.cache.has(key);
  },

  // Array to track usage order for LRU implementation
  keys: [],

  set(key, value) {
    // If cache is full, remove least recently used item
    if (this.keys.length >= MAX_CACHE_SIZE && !this.cache.has(key)) {
      const oldestKey = this.keys.shift();
      this.cache.delete(oldestKey);
    }

    // Add or update cache
    if (!this.cache.has(key)) {
      this.keys.push(key);
    } else {
      // Move to end if exists
      this.keys = this.keys.filter((k) => k !== key);
      this.keys.push(key);
    }

    this.cache.set(key, value);
  },

  size() {
    return this.cache.size;
  }
};

/**
 * Formats a color object for swatch display with memoization
 * @param {Object} color - Color object containing rbgs, name, trim_hex, and id
 * @returns {Object} Formatted color object for swatch display
 */
export const formatColorForSwatch = (color) => {
  // Skip cache for invalid inputs
  if (!color || !color.id) {
    return {
      color: null,
      title: undefined,
      trimColor: undefined,
      value: String(color?.id || '')
    };
  }

  // Use color id as cache key
  const cacheKey = String(color.id);

  // Return cached result if available
  if (colorFormatCache.has(cacheKey)) {
    return colorFormatCache.get(cacheKey);
  }

  try {
    // Otherwise, format the color and cache the result
    const formattedColor = {
      color: color.rbgs ? tinycolor(color.rbgs[0]).toHexString() : null,
      title: color.name || undefined,
      trimColor: color.trim_hex || undefined,
      value: String(color.id)
    };

    // Store in cache and return
    colorFormatCache.set(cacheKey, formattedColor);
    return formattedColor;
  } catch (error) {
    // Fallback for any errors in color processing
    return {
      color: null,
      title: color.name || undefined,
      trimColor: undefined,
      value: String(color.id)
    };
  }
};

/**
 * Selects popular colors based on predefined criteria
 * @param {Array} colors - Array of color objects
 * @param {number} [limit=14] - Maximum number of colors to return
 * @returns {{colors: Array, remaining: number}} Object containing selected colors and count of remaining colors
 */
export const selectPopularColors = (colors, limit = 14) => {
  // Prepare colors with RGB values
  const colorsWithRGB = colors.map((color) => ({
    ...color,
    rgbValues: parseRGB(color.rbgs[0])
  }));

  const selections = [
    // First all "best" picks
    ...findBestColors(colorsWithRGB, ['blue'], 'b', ['b', 'r', 'g']).slice(
      0,
      1
    ),
    ...findColorsBySimpleName(colorsWithRGB, 'heathered').slice(0, 1),
    ...findBestColors(colorsWithRGB, ['red'], 'r', ['r', 'g', 'b']).slice(0, 1),
    ...findBestWhiteColors(colorsWithRGB).slice(0, 1),
    ...findBestColors(colorsWithRGB, ['green'], 'g', ['g', 'r', 'b']).slice(
      0,
      1
    ),
    ...findBestColorCombinations(
      colorsWithRGB,
      ['purple'],
      ['g', 'r', 'b'],
      150
    ).slice(0, 1),
    ...findBestColorCombinations(
      colorsWithRGB,
      ['yellow', 'orange'],
      ['b', 'r', 'g'],
      300
    ).slice(0, 1),
    ...findColorsBySimpleName(colorsWithRGB, 'brown').slice(0, 1),
    // Then all "secondBest" picks
    ...findBestColors(colorsWithRGB, ['blue'], 'b', ['b', 'r', 'g']).slice(1),
    ...findColorsBySimpleName(colorsWithRGB, 'heathered').slice(1),
    ...findBestColors(colorsWithRGB, ['red'], 'r', ['r', 'g', 'b']).slice(1),
    ...findBestWhiteColors(colorsWithRGB).slice(1),
    ...findBestColors(colorsWithRGB, ['green'], 'g', ['g', 'r', 'b']).slice(1),
    ...findBestColorCombinations(
      colorsWithRGB,
      ['purple'],
      ['g', 'r', 'b'],
      150
    ).slice(1),
    ...findBestColorCombinations(
      colorsWithRGB,
      ['yellow', 'orange'],
      ['b', 'r', 'g'],
      300
    ).slice(1),
    ...findColorsBySimpleName(colorsWithRGB, 'brown').slice(1)
  ];

  // Remove nulls and duplicates
  const uniqueColors = [...new Set(selections.filter(Boolean))];

  // Add remaining colors if needed
  const remainingColors = colorsWithRGB.filter(
    (color) => !uniqueColors.includes(color)
  );

  const finalColors = [...uniqueColors, ...remainingColors].slice(0, limit);

  return {
    colors: finalColors,
    remaining: Math.max(0, colors.length - finalColors.length)
  };
};
