import {ColorSwatch} from '@customink/pigment-react';
import React from 'react';
import {rgbToHex} from '../../utils/color';

function PopularSwatches({
  onClickEvt,
  onMouseEnterEvt,
  popColors,
  remainingColorsCount
}) {
  return (
    <div className="pc-ProductCard-popSwatches">
      {popColors.length > 0 && (
        <>
          {popColors.map((color) => (
            <ColorSwatch
              className="pc-ProductCard-popSwatches-swatch"
              color={rgbToHex(color.rgbValues)}
              trimRgb={color.trim_hex}
              key={`popsw-${color.id}`}
              title={color.name || 'o_O'}
              onMouseEnter={(event) => onMouseEnterEvt(event, color)}
              onClick={(event) => onClickEvt(event, color)}
              aria-hidden="true"
              tabIndex="-1"
            />
          ))}
          {remainingColorsCount > 0 && (
            <span className="remainingColorsCount">
              +{remainingColorsCount}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default PopularSwatches;
