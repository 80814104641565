import React from 'react';
import {useRemoveFilters} from './useRemoveFilters';

const CustomClearRefinements = (props) => {
  const removeFilters = useRemoveFilters();

  const handleRemoveFilters = (event) => {
    event.preventDefault();
    if (props.quoteQuantityDirty) {
      props.onRemoveOrderSize();
      removeFilters();
    } else {
      removeFilters();
    }
  };

  return (
    <div className="pc-selectedFilters-removeAll">
      <a onClick={(event) => handleRemoveFilters(event)}>Clear Filters</a>
    </div>
  );
};

export default CustomClearRefinements;
