import {useMemo} from 'react';
import usePriceQuote from 'hooks/queries/quotes';
import priceGridQuantities from 'utils/priceGridQuantities';

export default function useProductCardPriceDetails(
  quotableProductId,
  minQty,
  singlesEnabled,
  specialty,
  frontColor,
  backColor,
  quantity
) {
  const quotableQuantities = useMemo(
    () =>
      [
        ...new Set([
          quantity,
          ...priceGridQuantities(minQty, singlesEnabled, specialty)
        ])
      ].sort((a, b) => parseInt(a, 10) - parseInt(b, 10)),
    [minQty, singlesEnabled, specialty, quantity]
  );

  const {
    data: quoteData,
    isError,
    isLoading
  } = usePriceQuote(
    quotableProductId,
    quotableQuantities,
    frontColor,
    backColor,
    true
  );

  const priceDetailsData = useMemo(
    () =>
      quoteData &&
      Object.fromEntries(
        quotableQuantities.map((qty) => [qty, quoteData[qty]])
      ),
    [quoteData, quotableQuantities]
  );

  return {
    isError,
    isLoading,
    priceDetailsData,
    quotableQuantities
  };
}
