import React from 'react';
import {useCurrentRefinements, useQueryRules} from 'react-instantsearch';
import {filterGroupHeader} from '../utils/algolia_filters_utils';
import {XClearCircleIcon} from '@customink/pigment-react';

const SelectedRefinements = (props) => {
  const {items: algoliaRules} = useQueryRules({});

  const filterGroupList = algoliaRules.find((item) =>
    Object.keys(item).includes('filterGroups')
  );

  const refinementValueMap = {
    price_level: {
      1: 'Budget',
      2: 'Moderate',
      3: 'Premium'
    },
    individual_ship_eligible: {
      true: 'Individual Ship Eligible'
    },
    has_singles_enabled_colors: {
      true: 'No Minimum'
    },
    specialty: {
      true: 'Specialty',
      false: 'Non-Specialty'
    },
    halftones: {
      true: 'Accepts Halftones'
    },
    international_ship_eligible: {
      true: 'International-Ship Eligible'
    },
    rush_delivery_turntimes: {
      3: '3 Days',
      7: '1 Week',
      10: '10 Days',
      12: '12 Days'
    }
  };

  const {items, canRefine, refine} = useCurrentRefinements(props);

  const capitalize = (str) =>
    str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

  const refinementValueAsString = (refinement) => {
    return refinement.attribute === 'min_order_qty'
      ? `Order Size: ${refinement.value}`
      : refinementValueMap?.[refinement.attribute]?.[refinement.value] ||
          capitalize(refinement.value);
  };

  const specialFilterTypeMapping = {
    rush_delivery_turntimes: 'fs-Rush Delivery Available',
    individual_ship: 'fs-individual_ship_eligible',
    min_order_qty: 'fs-order_qty'
  };

  const getFilterType = (attribute) => {
    if (specialFilterTypeMapping[attribute]) {
      return specialFilterTypeMapping[attribute];
    } else {
      return `fs-${filterGroupHeader(attribute, filterGroupList?.filterGroup)}`;
    }
  };

  const removeSelectedRefinement = (refinement) => {
    window.dataLayer.push({
      event: 'catalog_filter_remove',
      filter_remove_type: getFilterType(refinement.attribute),
      remove_remove_value: refinementValueAsString(refinement)
    });

    if (refinement.attribute === 'min_order_qty') {
      props.onRemoveOrderSize();
    }
    refine(refinement);
  };

  return items?.map((item, index) => {
    return item.refinements?.map((refinement) => {
      const attribute = refinement.attribute;
      const attributeValueKey = `${refinement.attribute}-${refinement.value}`;
      return (
        attribute !== 'compatible_type' &&
        attribute !== 'sort_by' && (
          <div
            className="pc-selectedFilters-selectedFilter"
            key={`${attributeValueKey}`}
            onClick={() => removeSelectedRefinement(refinement)}>
            {refinementValueAsString(refinement)}
            <XClearCircleIcon />
          </div>
        )
      );
    });
  });
};

export default SelectedRefinements;
