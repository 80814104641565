import {ArrowDownIcon, Link, Typography} from '@customink/pigment-react';
import React, {useState, useEffect, useRef} from 'react';
import {isCatalogPath, isIframer} from 'src/utils/url';
import {debounce} from 'throttle-debounce';

const visibleLinksCount = () => {
  if (window.innerHeight >= 920) return 8;
  if (window.innerHeight >= 815) return 7;
  if (window.innerHeight >= 710) return 6;
  if (window.innerHeight >= 610) return 5;
  return 4;
};

const CrossSubcategoryNavigation = ({subcategoryNavigation}) => {
  const [showAllLinks, setShowAllLinks] = useState(false);
  const [subcatNavState, setSubcatNavState] = useState({
    fullHeight: 800,
    truncatedHeight: 112,
    linksCount: 9
  });
  const catSubLinksRef = useRef(null);
  const linkRefs = useRef([]);

  const calculateHeights = React.useCallback(() => {
    if (!catSubLinksRef.current) return;

    const currentItemCount = linkRefs.current.filter(Boolean).length;
    const visibleCount = visibleLinksCount();

    requestAnimationFrame(() => {
      const totalHeight = linkRefs.current.reduce(
        (sum, ref) => sum + (ref?.offsetHeight || 0),
        0
      );

      const itemsToShow =
        currentItemCount <= visibleCount + 1 ? currentItemCount : visibleCount;

      const truncatedHeight = linkRefs.current
        .slice(0, itemsToShow)
        .reduce((sum, ref) => sum + (ref?.offsetHeight || 0), 0);

      setSubcatNavState({
        fullHeight: totalHeight,
        truncatedHeight: truncatedHeight,
        linksCount: currentItemCount
      });
    });
  }, [visibleLinksCount]);

  useEffect(() => {
    if (!catSubLinksRef.current) return;

    const handleResize = debounce(150, calculateHeights);
    window.addEventListener('resize', handleResize);

    let observer;
    if (window.ResizeObserver) {
      observer = new ResizeObserver(handleResize);
      observer.observe(catSubLinksRef.current);
    }

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [calculateHeights]);

  // parentCategory is the umbrella category that the subcategories belong to with the following unified
  // category structure:
  //    type Category = { name: :string, path: :string[] };
  const parentCategory = subcategoryNavigation?.parent_category;
  // A list of category objects with identical structure described above
  const categories = subcategoryNavigation?.sibling_categories;
  const currentCategory = categories?.find(
    (category) => category.path === window.location.pathname
  );

  const fireMetricsEvent = (category, action) => {
    CustomInk.Metrics.fireEvent(
      'plp subcat nav',
      category.toLowerCase(),
      action.toLowerCase()
    );
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'cross-subcategory-navigation',
      filter_value: category.toLowerCase(),
      filter_action: action.toLowerCase()
    });
  };

  const isSelected = (category) => category?.path === currentCategory?.path;

  if (!parentCategory || !categories) return null;

  return (
    <nav
      className="pc-FiltersGroup"
      style={{margin: '0 0.5rem', paddingBottom: '0.5rem'}}>
      <Typography
        variant="uiTextDefault"
        sx={{
          fontSize: '16px',
          lineHeight: '1.25',
          marginBottom: '0.25rem'
        }}>
        {parentCategory.name}
      </Typography>
      <div
        ref={catSubLinksRef}
        className="pc-FiltersGroup-values-catSubLinks"
        style={{
          maxHeight: showAllLinks
            ? `${subcatNavState.fullHeight}px`
            : `${subcatNavState.truncatedHeight}px`
        }}>
        {categories.map((category, index) => (
          <a
            key={category.path}
            ref={(el) => (linkRefs.current[index] = el)}
            className={`pc-FiltersGroup-values-catSubLink ${
              isSelected(category) ? 'selected' : ''
            }`}
            href={category.path}
            onClick={() => fireMetricsEvent(parentCategory.name, category.name)}
            {...(isIframer() &&
              !isCatalogPath(category.path) && {
                target: '_blank'
              })}>
            {category.name}
          </a>
        ))}
      </div>
      <Link
        button
        variant="uiTextTiny"
        onClick={() => {
          setShowAllLinks(!showAllLinks);
          fireMetricsEvent(
            parentCategory.name,
            showAllLinks ? 'show fewer' : 'show more'
          );
        }}
        style={{
          display: 'flex',
          fontSize: '13px',
          lineHeight: '1.25',
          textAlign: 'left',
          margin: '0 0.5rem',
          padding: '0.125rem 0 0.25rem',
          overflow: 'hidden',
          transitionDuration: '300ms',
          transitionDelay: '200ms',
          height: '22px',
          ...(subcatNavState.linksCount <= visibleLinksCount() + 1 && {
            padding: '0',
            height: '0px'
          })
        }}>
        {showAllLinks ? 'Show fewer' : 'Show more'}
        <ArrowDownIcon
          style={{
            fontSize: '16px',
            transition: 'transform 300ms',
            ...(showAllLinks && {
              transform: 'rotate(180deg)'
            })
          }}
        />
      </Link>
    </nav>
  );
};

export default CrossSubcategoryNavigation;
