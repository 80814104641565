import {
  Box,
  Divider,
  Stack,
  Typography,
  useMobileBreakpoint
} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import ElevateSuperRush from './components/ElevateSuperRush';
import MinQty from './components/MinQty';
import ProductCardColorSwatches from './components/ProductCardColorSwatches';
import ProductCardPriceDetails from './components/ProductCardPriceDetails/ProductCardPriceDetails';
import ProductCardRating from './components/ProductCardRating';
import ProductCardSizesInfo from './components/ProductCardSizesInfo';
import {getMinimumDeliveryDays} from './utils/delivery';

const ProductImage = styled('img')({
  '@media (min-width: 43.75em)': {
    aspectRatio: '1 / 1',
    maxHeight: 'unset'
  },
  maxHeight: '250px',
  minHeight: 120,
  objectFit: 'contain',
  order: 2,
  transitionDuration: '150ms',
  width: '100%'
});

// Extracted styled components to prevent recreation on each render
const ProductImageContainer = styled(Box)({
  '&:before': {
    boxShadow: '0px 0px 60px 24px #f5f5f5 inset',
    content: '""',
    inset: 0,
    mixBlendMode: 'darken',
    position: 'absolute'
  },
  '@media (min-width: 43.75em)': {
    flexBasis: 'unset',
    width: '100%'
  },
  borderRadius: '0.5rem',
  display: 'flex',
  flexBasis: '40%',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  transitionDuration: '150ms'
});

const ProductCardContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile'
})(({isMobile}) => ({
  '&:hover': {
    backgroundColor: 'white',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.06),0px 8px 16px -4px rgba(0, 0, 0, 0.15)',
    margin: '-0.75rem',
    padding: '0.75rem',
    zIndex: 1
  },
  backgroundColor: 'transparent',
  borderRadius: '0.5rem',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: isMobile ? 'row' : 'column',
  gap: isMobile ? '0.75rem' : '0.5rem',
  margin: '-0.375rem',
  padding: '0.375rem',
  position: 'relative',
  transitionDuration: '150ms',
  WebkitTapHighlightColor: 'transparent'
}));

const ProductTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'compact'
})(({compact = false}) => ({
  color: '#000000db',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  lineHeight: '1.25',
  marginBottom: '-0.15em',
  marginTop: compact ? 'unset' : '-0.155em'
}));

// this is a Placeholder for the Product Card
export default function ProductCard({
  backColor,
  categoryImages,
  defaultUnitPrices,
  frontColor,
  generalComments,
  minQty,
  name,
  noQtyLimit,
  productColors,
  productId,
  quantity,
  ratingCount,
  ratingScore,
  rushDeliveryTurntimes,
  singlesEnabledColors,
  sizes,
  specialty
}) {
  const isMobile = useMobileBreakpoint();

  const minDeliveryDays = getMinimumDeliveryDays(rushDeliveryTurntimes);

  const onSwatchClick = useCallback((swatch) => {
    // TODO: implement this
    // eslint-disable-next-line no-console
    console.log('swatch clicked', swatch);
  }, []);

  const onSwatchHover = useCallback((swatch) => {
    // TODO: implement this
    // eslint-disable-next-line no-console
    console.log('swatch hovered', swatch);
  }, []);

  // TODO: Remove this once we have proper image component implementation (this was a quick fix for demo purposes)
  const preferredColorId = useMemo(() => {
    return categoryImages?.find((cat) => cat.id === 16)?.preferred_color_id;
  }, [categoryImages]);

  const firstColor = useMemo(() => {
    return (
      productColors.find((color) => color.id === preferredColorId) ||
      productColors[0]
    );
  }, [productColors, preferredColorId]);

  // Memoize the swatches component to prevent unnecessary re-renders
  const swatchesComponent = useMemo(
    () => (
      <Stack direction="row" alignItems="flex-start">
        <ProductCardColorSwatches
          onSwatchClick={onSwatchClick}
          onSwatchHover={onSwatchHover}
          productColors={productColors}
        />
      </Stack>
    ),
    [onSwatchClick, onSwatchHover, productColors]
  );

  return (
    <ProductCardContainer isMobile={isMobile}>
      {/* Product Image */}
      <ProductImageContainer>
        <ProductImage src={firstColor.image_url} alt={name} />
      </ProductImageContainer>

      {/* Product Details */}
      <Stack gap="0.5rem" width={isMobile ? '60%' : '100%'} flex={1}>
        {/* Conditionally render components based on viewport */}
        {isMobile && <ProductTitle compact>{name}</ProductTitle>}

        {swatchesComponent}

        {/* Product Title - desktop only (after swatches) */}
        {!isMobile && <ProductTitle>{name}</ProductTitle>}

        {/* Rating and Sizes  */}
        <Stack direction="row" gap="0.5rem" alignItems="center">
          <ProductCardRating
            count={ratingCount}
            score={ratingScore}
            generalComments={generalComments}
            name={name}
          />
          <Divider orientation="vertical" flexItem />
          <ProductCardSizesInfo productId={productId} sizes={sizes} />
        </Stack>

        {/* Price and Details */}
        <ProductCardPriceDetails
          backColor={backColor}
          defaultUnitPrices={defaultUnitPrices}
          frontColor={frontColor}
          isMobile={isMobile}
          minQty={minQty}
          productId={productId}
          name={name}
          quantity={quantity}
          singlesEnabled={singlesEnabledColors}
          specialty={specialty}
        />

        {/* Delivery Rush Badge */}
        {minDeliveryDays && (
          <ElevateSuperRush deliveryRushDays={minDeliveryDays} />
        )}

        {/* Minimum qty */}
        <MinQty minQty={noQtyLimit ? null : minQty} />
      </Stack>
    </ProductCardContainer>
  );
}

ProductCard.propTypes = {
  backColor: PropTypes.number.isRequired,
  categoryImages: PropTypes.array,
  defaultUnitPrices: PropTypes.arrayOf(PropTypes.object).isRequired,
  frontColor: PropTypes.number.isRequired,
  generalComments: PropTypes.string,
  minQty: PropTypes.number,
  name: PropTypes.string.isRequired,
  noQtyLimit: PropTypes.bool,
  productColors: PropTypes.array.isRequired,
  productId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  ratingCount: PropTypes.number,
  ratingScore: PropTypes.number,
  rushDeliveryTurntimes: PropTypes.array,
  sizes: PropTypes.array,
  specialty: PropTypes.bool.isRequired
};
