import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useInstantSearch, useQueryRules} from 'react-instantsearch';
import {Box, useMobileBreakpoint, Button} from '@customink/pigment-react';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import FilterGroup from './FilterGroup';
import ColorFilterGroup from './ColorFilterGroup';
import PriceLevel from './PriceLevel';
import IndividualShipEligible from './IndividualShipEligible';
import MinimumQuantity from './MinimumQuantity';
import Specialty from './internal/Specialty';
import Halftones from './internal/Halftones';
import InternationalShipEligible from './internal/InternationalShipEligible';
import InternalFilterGroup from './internal/InternalFilterGroup';
import MinOrderQty from './internal/MinOrderQty';
import CrossSubcategoryNavigation from './CrossSubcategoryNavigation';
import RushDeliveryTurntimesFilterGroup from './RushDeliveryTurntimesFilterGroup';
import {useRemoveFilters} from './useRemoveFilters';

const DynamicRefinementsList = (props) => {
  const filtersCountainerRef = useRef(null);
  const isMobile = useMobileBreakpoint();
  const removeFilters = useRemoveFilters();
  const {items: algoliaRules} = useQueryRules();
  const hiddenSearchFilters = algoliaRules.find((item) =>
    Object.keys(item).includes('hiddenSearchFilters')) || {hiddenSearchFilters: []};

  const hiddenFilters = hiddenSearchFilters?.hiddenSearchFilters || [];

  // More filter groups may need to be discovered
  const filterGroupMap = {
    colors: 'colors.simple_names',
    brand: 'fg-brand',
    min_qty: 'has_singles_enabled_colors',
    subcat_nav: 'fg-subcat_nav'
  };

  const internalFilters = [
    'specialty',
    'halftones',
    'international_ship_eligible',
    'brand',
    'broad_category_names',
    'sub_category_names',
    'price_level',
    'desired_quantity',
    'search_data_material',
    'min_qty',
    'min_order_qty',
    'no_minimum',
    'rush_turn_time',
    'decoration_method',
    'turn_time',
    'weight'
  ];

  const internalFilterGroupMap = {
    material: 'search_data_material',
    decoration: 'decoration_method'
  };

  const sortByNameFilterGroups = ['fg-brand'];
  const facetLimit = 100;
  const {results} = useInstantSearch(props);
  const {
    filterGroups,
    refinementItems,
    filterValues,
    internalCatalog,
    currentUserInternal,
    filterGroupList,
    subcategoryNavigation,
    siteWideSearch
  } = props;

  const filterGroupToFacetMap = filterGroups.reduce(
    (acc, v) => ({
      ...acc,
      [v]: filterGroupMap[v] || v
    }),
    {}
  );

  const hiddenFiltersByUser = currentUserInternal ? hiddenFilters : hiddenFilters.concat(internalFilters);
  
  const sortByName = (filterGroup) => {
    return sortByNameFilterGroups.includes(filterGroup);
  };

  // Track results count separately from Algolia's results to ensure consistent updates on iOS
  const [displayCount, setDisplayCount] = useState(results?.nbHits || 0);
  // Keep a reference to the latest results to avoid stale values in touch event handlers
  const resultsRef = useRef(results);

  // Update the display count whenever Algolia results change
  useEffect(() => {
    if (results?.nbHits !== undefined) {
      resultsRef.current = results;
      setDisplayCount(results.nbHits);
    }
  }, [results]);

  const hideFilters = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Get the latest count from our ref to ensure we have the most up-to-date value
    const currentCount = resultsRef.current?.nbHits || 0;
    setDisplayCount(currentCount);

    if (filtersCountainerRef.current) {
      filtersCountainerRef.current.style.display = 'none';
      CustomInk.Metrics.fireEvent('catalog filter', 'hide all');
    }
  };

  const getRenderedFilterGroups = (algoliaFacets) => {
    if (!algoliaFacets || !algoliaFacets.length) {
      return filterGroups;
    } else {
      const algoliaFgs = algoliaFacets.filter(item => !hiddenFiltersByUser.includes(item) && !item.startsWith('min_qty_colors')) 
      const filterGroupSlugs = filterGroups.map(item => filterGroupToFacetMap[item]);
      const remainingItems = algoliaFgs.filter(item => !filterGroups.includes(item) && !filterGroupSlugs.includes(item));
      const searchFgs = [...filterGroupSlugs, ...remainingItems]
      const renderedFilters = 
        siteWideSearch 
        ? searchFgs
        : filterGroups.map(item => filterGroupToFacetMap[item]);
  
      return renderedFilters;
    }
  }

  const renderFilterGroups = () => {
    try {
      // Check if Algolia is loaded with below line
      const algoliaFacets = Object.keys(results['_rawResults'][0]['facets']);
      const renderedFilterAttributes = getRenderedFilterGroups(algoliaFacets);
      return renderedFilterAttributes.map((attribute) => {
        if (attribute === 'colors.simple_names') {
          // Fetch the sort order for colors from a conditionless Algolia rule
          const sortBy =
            results['_rawResults'][0]['userData'].find(
              (obj) => obj.filterValuesOrder
            ).filterValuesOrder['colors.simple_names'] || [];
          return (
            <React.Fragment key={attribute}>
              <ColorFilterGroup
                attribute={attribute}
                limit={facetLimit}
                sortBy={sortBy}
              />
              <ColorFilterGroup
                attribute="min_qty_colors.simple_names"
                limit={facetLimit}
                sortBy={sortBy}
                operator="and"
              />
            </React.Fragment>
          );
        } else if (attribute === 'price_level') {
          return (
            <PriceLevel
              attribute={attribute}
              key={attribute}
              limit={facetLimit}
              sortBy={['name']}
            />
          );
        } else if (attribute === 'individual_ship_eligible') {
          return (
            <IndividualShipEligible
              attribute={attribute}
              key={attribute}
            />
          );
        } else if (attribute === 'has_singles_enabled_colors') {
          return (
            <MinimumQuantity
              attribute={attribute}
              key={attribute}
            />
          );
        } else if (attribute === 'rush_delivery_turntimes') {
          return (
            <RushDeliveryTurntimesFilterGroup
              attribute={attribute}
              key={attribute}
            />
          );
        } else if (attribute === 'compatible_type') {
          return null;
        } else {
          return ((siteWideSearch && attribute) || filterValues) && (
            (
              <FilterGroup
                key={attribute}
                attribute={attribute}
                limit={99}
                showMore={true}
                showMoreLimit={facetLimit}
                sortBy={sortByName(attribute) ? ['name'] : ['count']}
                values={filterValues ? filterValues[attribute] : undefined}
                sizeOrder={props.sizeOrder}
                filterValuesOrder={props.filterValuesOrder}
                filterGroupList={filterGroupList}
              />
            )
          );
        }
      });
    } catch (error) {
      console.log('No results from Algolia');
    }
  };

  const renderInternalFilterGroups = () => {
    try {
      // Check if Algolia is loaded with below line
      Object.keys(results['_rawResults'][0]['facets']);
      return internalFilters
        .filter((intFilter) => !filterGroups.includes(intFilter))
        .map((internalFilter) => {
          if (internalFilter === 'specialty') {
            return (
              <Specialty
                attribute={internalFilter}
                key={`internal-${internalFilter}`}
                internalCatalog={internalCatalog}
              />
            );
          } else if (internalFilter === 'halftones') {
            return (
              <Halftones
                attribute={internalFilter}
                key={`internal-${internalFilter}`}
                internalCatalog={internalCatalog}
              />
            );
          } else if (internalFilter === 'international_ship_eligible') {
            return (
              <InternationalShipEligible
                attribute={internalFilter}
                key={`internal-${internalFilter}`}
                internalCatalog={internalCatalog}
              />
            );
          } else if (internalFilter === 'min_order_qty') {
            return (
              <MinOrderQty
                attribute={internalFilter}
                key={`internal-${internalFilter}`}
                internalCatalog={internalCatalog}
                quantity={props.quantity}
                quoteQuantityDirty={props.quoteQuantityDirty}
                handleQuantityChange={props.handleQuantityChange}
                onRemoveOrderSize={props.onRemoveOrderSize}
                algoliaIndex={props.algoliaIndex}
              />
            );
          } else {
            return (
              <InternalFilterGroup
                attribute={
                  internalFilterGroupMap[internalFilter] || internalFilter
                }
                key={`internal-${internalFilter}`}
                internalCatalog={internalCatalog}
                limit={7}
                showMore={true}
                showMoreLimit={facetLimit}
              />
            );
          }
        });
    } catch (error) {
      console.log('No results from Algolia');
    }
  };

  const filtersCount = refinementItems.filter(
    (item) => item.attribute !== 'sort_by'
  ).length;

  const handleClearFilters = (e) => {
    e.preventDefault();
    removeFilters();
    e.currentTarget.blur();
  };

  return (
    <div className="pc-Filters" ref={filtersCountainerRef}>
      <header className="pc-Filters-header">
        Filters{` `}
        {filtersCount > 0 && `(${filtersCount})`}
        <span className="pc-Filters-headerClose"></span>
      </header>
      <div className="pc-Filters-body">
        {!isMobile && (
          <CrossSubcategoryNavigation
            attribute={filterGroupToFacetMap['subcat_nav']}
            key={filterGroupToFacetMap['subcat_nav']}
            subcategoryNavigation={subcategoryNavigation}
          />
        )}
        <div className="pc-Filters-scrollable">
          {renderFilterGroups()}
          {currentUserInternal &&
            internalCatalog &&
            renderInternalFilterGroups()}
        </div>
      </div>
      {isMobile && (
        <Box
          sx={{
            position: 'sticky',
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            bottom: 0,
            width: '100%',
            padding: '0.75rem 1rem',
            backgroundColor: 'white',
            borderTop: `1px solid ${designTokens.color.gray.accent.value}`
          }}>
          <Button
            sx={{flexGrow: 0}}
            variant="secondary"
            onTouchEnd={handleClearFilters}
            onMouseUp={handleClearFilters}
            disabled={!filtersCount}>
            Clear Filters
          </Button>
          <Button
            sx={{flexGrow: 1}}
            onTouchEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
              hideFilters(e);
            }}>
            {/* Using displayCount instead of results.nbHits directly ensures consistent updates on iOS */}
            View {displayCount} Results
          </Button>
        </Box>
      )}
      <header className="pc-Filters-footer">Apply</header>
    </div>
  );
};

DynamicRefinementsList.propTypes = {
  filterGroups: PropTypes.array
};

export default DynamicRefinementsList;
